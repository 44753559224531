@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .modal__bottom {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    overflow: hidden;
    max-height: 100dvh;

    .react-responsive-modal-modal {
      overflow: visible;
      transform: translateY(100%);
    }

    .modal__container {
      max-width: 100%;
      border-radius: 32px 32px 0 0;
      padding-top: 40px;
      flex-direction: column;
      display: flex;
      max-height: 90dvh;
      //animation: modal__container_animate 1s linear;
      transition: var(--transition);

      &:before {
        content: '';
        position: absolute;
        align-self: center;
        top: -8px;
        background: rgba(255, 255, 255, 0.1);
        width: 40px;
        height: 4px;
        border-radius: 8px;
      }
    }

    .modal-close {
      top: 16px;
      right: 16px;
    }

    .modal__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;

      h4 {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .modal-footer {
      margin-bottom: 24px;
    }
  }
  .modal-footer {
    width: 100%;
    margin-top: 32px;
    gap: 16px;
  }
  .modal__container.modal__lvl {
    padding-top: 16px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;

    &:after {
      content: '';
      position: absolute;
      background: rgba(255, 222, 103, 0.5);
      width: 220px;
      height: 220px;
      border-radius: 50%;
      align-self: center;
      filter: blur(40px);
    }

    .avatar {
      width: 48px;
      height: 48px;
      min-width: 48px;
    }

    .modal__lvl_item-header .avatar {
      width: 100px;
      height: 100px;
      min-width: 100px;
    }

    .modal__lvl_item {
      padding: 24px 16px 16px;
      max-width: 100%;

      p {
        text-align: center;
        margin-top: 12px !important;
      }

      h4 {
        text-align: center;
        margin-top: 16px !important;
      }
    }

    .modal__lvl_item-container {
      padding: 0 4px;
    }

    .modal__lvl_btn {
      display: flex !important;
    }

    .slick-dots {
      position: relative;
      bottom: 0;
      height: 8px;
      display: flex !important;
      justify-content: center;
      margin-top: 24px;
    }
  }
  .modal__container.modal__chat__image_confirm {
    padding-top: 16px;
    .card__active_16 {
      font-size: 14px;
      line-height: 20px;
      margin-top: 16px;
      &:first-child {
        margin-top: 0;
      }

    }
  }
  .modal__chat__image_confirm-head {
    flex-direction: column;
    &:after {
      content: '';
      position: absolute;
      width: 13px;
      height: 13px;
      left: 56px;
      top: 56px;
      background: url("../../../pages/HomePage/assets/images/line.svg") no-repeat center/ 100%;
    }
    .avatar {
      width: 40px;
      height: 40px;
      min-width: 40px;

    }

    h5 {
      position: absolute;
      top: 24px;
      left: 64px;

    }
  }
  .modal__chat_image_request-message {
    margin-top: 8px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-direction: column-reverse;
    padding: 16px;
    .progress__container {
      margin-bottom: 12px;
    }
  }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
  .modal-footer {
    flex-direction: column;

    .btn {
      width: 100%;
    }
  }
  .modal__gallery {
    //width: 100vw;
    //height: 100%;
    //max-height: 100%;
    //.slick-slider {
    //  width: 100vw;
    //}
    //img {
    //  width: auto;
    //  height: auto;
    //  object-fit: contain;
    //  max-width: 100%;
    //  max-height: 100%;
    //}
    //.slick-list {
    //  border-radius: 0;
    //}
  }

}


/* touchscreens */
@media (hover: none) or (max-width: 991px) {
  .modal__adult_18 {
    h4 {
      text-align: center;
    }

    .modal__legacy_head {
      background-color: var(--bg-main);

      .ps-2 {
        padding-left: 0 !important;
      }

      .text-medium {
        padding-left: 48px;
        padding-top: 8px;
      }

      .modal__legacy_message {
        margin-top: 16px;
        border-radius: 24px;
        font-family: var(--font-regular);
        font-size: 14px;
        line-height: 20px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 13px;
          height: 13px;
          top: -8px;
          left: 40px;
          background: url("../../../pages/HomePage/assets/images/line.svg") no-repeat center;
        }
      }
    }

    .avatar {
      position: absolute;
    }
  }
  .generate__modal_character-list {
    padding-bottom: 16px;
  }
  .modal__gallery_content {
    width: 100%;
    //height: calc(100dvh - 240px);
    display: flex;
    flex: 1;
    position: relative;

    .btn-expand, .btn-trash {
      display: none;
    }

  }
  .modal__gallery_content-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    div {
      height: 100%;
    }
  }
  .gallery-modal-image {
    border-radius: 0;
    flex: 1;
    width: 100%;
    max-height: 100%;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    img {
      position: absolute;
      width: auto;
      border-radius: 0;
      max-height: 100%;
    }

  }
  .modal__gallery_header, .modal__gallery_footer {
    display: flex;
  }
}

/* mouse */
@media (hover: hover) {
  /* ... */
}