@media only screen and (max-width: 1199px) {
  .home-top-section {
    margin-top: 0 !important;
  }
  .home__footer-btns  {
    flex-wrap: wrap;
    .btn {
      min-width: 100%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .home__footer_18 {
    height: 64px;
  }
  .home-page .home-top-footer {
    display: none;
  }
  .home-page .home-top-footer .btn-outline-secondary {
    margin-top: 16px;
  }
  .home__footer_bottom-right {
    width: 100%;

    .form-group {
      flex: 1;
    }
  }
  .home-page .home-models-list{
    margin-top: 0;
  }
  .home-page {
    margin-top: -8px;
    padding-top: 0px;
  }
  .home-page section {
    margin-top: 32px;
  }
  .home-top-section-container {
    display: block;

  }
  .home-top-left {
    position: relative;
    margin-top: 0;
    left: 0;
    right: 0;
    width: auto;

    .gender-select {
      display: none;
    }
  }

  .home-top-right {
    max-width: 100%;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    z-index: 1;
    padding-top: 24px;
    &:before {
      display: none;
      background: rgba(7, 5, 19, 0.6);
      backdrop-filter: blur(30px);
      -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, var(--bg-main) 100px, var(--bg-main) 100%);
      mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, var(--bg-main) 100px, var(--bg-main) 100%);
      top: -120px;
    }
  }

  .home-top-right-content {
    position: static;
    text-align: center;
    width: 100%;
  }
  .top-carousel {
    display: none;
  }
  .top__carousel_mobile {
    display: block;
    min-height: 340px;
  }
  .top-carousel-item {
    width: auto;
    padding: 0;
    border-radius: 4px 4px 24px 4px;
    overflow: visible;

    &:before {
      display: none;
    }

    .model-card-about {
      display: none;
    }
  }
  .top-carousel .model-card {
    transform: none !important;
    border-radius: 4px 4px 24px 4px;

    img {
      height: 100%;
      object-position: top;
      border-radius: 4px 4px 24px 4px;
    }
  }
  .top-carousel-item-footer {
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    border-radius: 24px 24px 24px 4px;
    padding: 12px 16px 16px;
    background: rgba(7, 5, 19, 0.6);
    backdrop-filter: blur(16px);
    flex-direction: column;
    align-items: flex-start;
    //opacity: 0;

    .avatar {
      display: none;
    }

    .name-age {
      font-size: 16px;
      line-height: 24px;
      margin-left: 0 !important;
    }

    .model-online {
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin-left: -2px;
      margin-right: 4px;
      background: rgba(245, 11, 235, 0.2);
      position: relative;

      &:after {
        content: '';
        height: 4px;
        width: 4px;
        background-color: var(--tint-color);
        position: absolute;
        border-radius: 8px;
      }
    }

    .carousel__item_footer-message {
      display: block;
      white-space: nowrap;
    }

    .carousel__item_footer-top {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .home-top-text {
    display: none;
  }
  .home-top-right-content .btn-icon {
    //min-width: 64px;
    display: none !important;
  }
  .home-page h1 {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .home-page h2 {
    text-align: center;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }
  .home-top-footer {
    margin-top: 0;

    .btn.btn-lg.btn-outline-third {
      display: none;
    }
  }
  .home-page .home-models {
    padding: 16px 0;
  }
  .home-models {
    background: rgba(255, 255, 255, 0.1);
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    border-radius: 32px;
    margin-top: 32px !important;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .home-footer-section {
    margin-top: 16px !important;

    background: rgba(255, 255, 255, 0.1);
    border-radius: 32px;

  }
  .home-footer {
    margin: 0;
    padding: 24px 0 16px;

    &:after {
      display: none;
    }

    &:before {
      width: 80px;
      height: 80px;
      border-radius: 160px;
      bottom: auto;
      left: auto;
      right: auto;
      top: 0;
      background-color: var(--tint-color);
    }

    h2 {
      text-align: center !important;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 24px;
      text-transform: none;

      .text-tint {
        color: rgba(255, 255, 255, 0.6);
      }

      br {
        display: none;
      }
    }
  }
  .home-footer-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    h2 {
      font-size: 14px;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.6);
      text-transform: none;

      br {
        display: none;
      }
    }
  }
  .home-footer-logo {
    margin: 0 auto 8px;
    height: 24px;
    display: block;
  }
  .home-footer-nav {
    .col-6 {
      display: none
    }
  }
  .home-footer-nav .form-control {
    font-size: 14px;
  }
  .home__footer_bottom {
    border-radius: 26px;
    margin: 0;
    margin-top: 16px;
    gap: 16px;

    a {
      color: #fff;
    }
  }
  .top-carousel-item {
    z-index: 0 !important;
    width: 240px;
    min-width: 240px;
    left: auto;
    transform: translate(180%, 40%) rotate(20deg);
    pointer-events: none !important;

    &.item__position_0 {
      transition: all 2s ease;
      transform: translate(-180%, 40%) rotate(-20deg);
      opacity: 0;

    }

    &.item__position_1 {
      transition: all 2s ease;
      transform: translate(-90%, 20%) rotate(-10deg);
      opacity: 1;
      z-index: 0;
    }

    &.item__position_2 {
      pointer-events: auto !important;
      z-index: 3 !important;
      transition: all 2s ease;
      transform: translateX(0);
      opacity: 1;
    }

    &.item__position_3 {
      transition: all 2s ease;
      transform: translate(90%, 20%) rotate(10deg);
      opacity: 1;
    }

  }
  .top-carousel-item:hover {
    z-index: 2 !important;

    .model-card {

      transform: rotate(0) !important;
    }

    .top-carousel-item-footer {
      opacity: 1;
    }
  }
  .top-carousel:has(.top-carousel-item:hover) .top-carousel-item:not(:hover) .model-card,
  .top-carousel:has(.top-carousel-item:hover) .top-carousel-item:not(:hover):nth-child(2n) .model-card {
    transform: rotate(0) !important;
  }
  .home__footer-btns {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto 24px;
    gap: 32px;
    > .d-flex {
      width: 100%;
    }
    .btn-content {
      padding-left: 24px!important;
    }
    .btn {

      min-width: 288px;
      flex: 1;
    }

    .form-group {
      width: 100%;
    }
  }
  .home-top-right-content .btn__user-animate {
    align-self: center !important;
    height: 48px;
    min-height: 48px;
    border-radius: 16px;
    .btn-content {
      padding-left: 20px !important;
      padding-right: 24px !important;
      min-height: 48px;
      height: 48px;
      span {
        padding-left: 14px !important;
        padding-right: 0 !important;
      }
    }
    .btn__user_animate-icon {
      width: 20px;
    }
    .btn__user_animate-icon-ellipse svg{
      width: 24px;
      height: 24px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .top-carousel {
    height: 272px;
  }
  .top-carousel-item {
    width: 190px;
    min-width: 190px;
  }
  .home__footer {
    flex-direction: column;
    align-items: center;

    h2 {
      text-align: center !important;
    }

  }
  .top__carousel_mobile {
    min-height: 270px;
  }
}

@media only screen and (max-width: 575px) {
  .top__carousel_mobile {
    min-height: 186px;
    .model-card {
      aspect-ratio: 1/ 1.1;
    }
  }
  .home__footer-btns > .d-flex {
    flex-direction: column;
    width: 100%;

    .btn {
      width: 100%;
    }
  }
  .top-carousel {
    height: 244px;
  }
  .top-carousel-item {
    width: 170px;
    min-width: 170px;
  }
  .home__footer_bottom-right {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    gap: 16px;
  }
  .home__footer_bottom-right .form-group {
    width: 100%;
  }
  .home__footer .btn {
    width: 100%;
  }
  .home__footer_info-btns {
    flex-direction: column;
    gap: 16px;

    a {
      margin: 0;
    }
  }
  .home-page .home-top-right-content .btn-secondary {
    width: 100%;
    min-width: 100% !important;

  }
  .home__header_premium-btn {
    .btn-content {
      //padding-right: px!important;
    }

    .main__title {
      display: none;
    }

    .alternative__title {
      display: block;
    }
  }
  .home__footer_bottom {
    padding: 12px;
    padding-top: 16px;
    flex-direction: column;
    .gap-3 {
      width: 100%;
      gap: 12px!important;
    }
  }
  .home-page {
    .tabs {
      width: 100%;
    }
    .tab {
      min-width: auto;
      padding: 0 8px;
    }
  }
  .home-page .home-models h2 {
    display: none;
  }
  .home-page h1 {
    display: none;
  }
  .create__ai_homeList-text {
    bottom: 16px;
    padding: 0 16px;
    font-size: 20px;
    line-height: 24px;
    .mt-3 {
      margin-top: 8px!important;
    }
  }
}

@media only screen and (max-width: 340px) {
  .home-top-right-content .btn__user-animate {
    width: 100%;

    .btn__user_animate-icon {
      display: none;
    }

    .btn-content {
      padding: 0 24px !important;
      span {
        padding: 0 !important;
      }
    }

  }
}

/* touchscreens */
@media (hover: none) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
}