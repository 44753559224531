@media only screen and (max-width: 1700px) {
  header .gender-select {
    position: relative;
    left: auto;
  }
  .header-center {
    padding-left: 0;
  }

}

@media only screen and (max-width: 1550px) {
  .header-center {
    position: static;

    .gender-select {
      left: 124px;
    }
  }
}

@media only screen and (max-width: 1299px) {
  .header {
    margin-top: 16px;

  }
  .header-left, .header-logo, .header-right {
    position: relative;
  }
  .header-center {
    justify-content: space-between;
    padding-left: 54px;

    .gender-select {
      left: 112px;
    }

    nav {
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
  }
  .header .gender-select {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .home-top-right-content .btn__user-animate .btn-content {
    padding-left: 24px !important;

    span {
      text-align: center !important;
      padding: 0 !important;
      padding-right: 54px !important;
    }
  }
  .header-avatar .avatar {
    min-width: 40px;
    width: 40px;
    height: 40px;

    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }
  .header__main {
    display: none;
  }
  .header__secondary {
    display: flex;
  }
  .btn__header_coins {
    .btn-content {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {

  .header__secondary_logo {
    display: none;
  }
  .header__secondary_logo-small {
    display: block;
  }
  .header__secondary_center {
    //padding: 0 80px;
    text-align: center;

    h2 {

      line-height: 24px;
    }

  }
}

@media only screen and (max-width: 430px) {

  .btn.home__header_premium-btn {
    .btn-content {
      padding: 0;
    }
    .react-icon {
      margin: 0;
    }
    .alternative__title {
      display: none!important;
    }
  }
}


/* touchscreens */
@media (hover: none) or (max-width: 767px) {

}

/* mouse */
@media (hover: hover) {
  /* ... */
  .header__menu_plus:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}