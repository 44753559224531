.create__ai_page {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  padding: 0 var(--page-padding);

  .page-title {
    padding-top: 28px;
    background: url("../../../../assets/icons/create_ai.svg") no-repeat center top;
    margin-bottom: 4px;
    line-height: 60px;
  }
}


.create__ai_title {
  position: relative;
  display: flex;
  align-items: center;
  margin: 16px 0;
  width: 100%;
  justify-content: space-between;
  min-height: 64px;

  h4 {
    flex: 1;
    padding: 0 12px;
    text-align: center;
  }

  .btn_left {
    position: relative;
    min-width: 64px;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .btn_right {
    position: relative;
    min-width: 64px;

    &.hidden {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}

.create__ai_steps-container {
  margin-top: 32px;
  width: 100%;
  position: relative;
}

.create__ai_steps-container:has(.create__ai_steps-image) {
  padding: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;

  .create__ai_steps {
    padding: 8px;
    margin-top: 8px;
  }
}

.create__ai_steps-image {
  display: flex;
  position: relative;
  padding-right: 64px;
  align-items: center;

  .input__file_container, .input__file_wrap {
    position: static;

    &:before {
      display: none;
    }
  }

  .input__file {
    padding: 0;
    border: none;
  }

  .input__file_left {
    margin: 0;
    width: 64px;
    height: 64px;
    min-width: 64px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    img {
      border-radius: 16px;
    }
  }

  .input__file_trash {
    position: absolute;
    right: 20px;
  }

  .input__file_content {
    display: none;
  }

  p {
    margin-left: 16px;
    max-width: 320px;
    width: 100%;
  }
}
.create__ai_steps-image:has(.input__file_wrap.uploading) {
  p {
    display: none;
  }
  .input__file_content {
    display: block;
  }
}

.create__ai_steps {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;

}

.create__ai_step {
  flex: 1;
  height: 8px;
  border-radius: 8px;
  transition: var(--transition);
  cursor: pointer;
  background-color: var(--bg-main);
  border: 1px solid var(--bg-main);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    opacity: 0;
    left: 4px;
    right: 4px;
    pointer-events: none;
    height: 2px;
    border-radius: 1px;
    background-color: var(--bg-main);
  }

  &.create__ai_step-current {
    border-color: var(--secondary-color);
  }

  &.create__ai_step-complete {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);

    &:hover:after {
      opacity: 1;
    }
  }
}

.create__ai_item {
  user-select: none;
  border-radius: 20px;
  height: 100%;
  border: 1px solid var(--border-color);
  transition: var(--transition);
  padding: 16px;
  position: relative;
  cursor: pointer;
  display: flex;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 20px;
    border: 1px inset var(--tint-color);
    pointer-events: none;
    transition: var(--transition);
    filter: blur(5px);
    opacity: 0;
  }

  &.active {
    border-color: var(--tint-color);

    &:before {
      opacity: 1;
    }

    .create__ai_item-arrow, .create__ai_item-edit {
      opacity: 1 !important;
    }

    .create__ai_item-footer {
      //max-width: 0%;
      padding-right: 24px;
      padding-left: 8px !important;
      text-align: left;
      align-items: flex-start;
      //position: relative;
    }
  }
}

.create__ai_item-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.create__ai_item.small, .create__ai_style .create__ai_item.small {
  flex-direction: column;
  padding: 4px;
  border-radius: 20px;
  min-width: 56px;
  min-height: 56px;

  img {
    width: 100%;
    border-radius: 16px;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: top;
  }

  .create__ai_item_image-wrap {
    width: 100%;
    border-radius: 16px;
    aspect-ratio: 1;
    background: rgba(255, 255, 255, 0.1);

  }

  .create__ai_item-footer {
    //min-height: 36px;
    padding: 0 16px 0;
    margin: 10px 0 6px;
    font-size: 14px;
    line-height: 20px;
    //transition: var(--transition);
    max-width: 100%;
    text-align: center;
    white-space: pre-wrap;
    overflow: hidden;
    position: relative;
  }

  .create__ai_item-arrow {
    right: 4px;
    top: auto;
    opacity: 0;
    transition: none;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.create__ai_item.xs {
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 20px;
  width: auto !important;
  min-width: 56px;
  height: 56px;

  img {
    width: 100%;
    border-radius: 16px;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: top;
  }

  .create__ai_item-arrow {
    right: 0;
    bottom: auto;
    top: auto;
    opacity: 0;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}


.create__ai_item-arrow {
  position: absolute;
  top: 16px;
  right: 16px;
  opacity: 0.6;
}


.create__ai_style {
  width: 100%;

  .create__ai_list {
    justify-content: center;

  }

  .create__ai_item {
    display: flex;

    img {
      width: 140px;
      border-radius: 4px;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }


}

.create__ai_list-age {
  justify-content: center;
}

.create__ai_list-age .create__ai_item {
  width: 44px;
  min-width: 44px;
  height: 44px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;

  &:active, &.active {
    .create__ai_item-arrow {
      position: relative;
    }
  }
}

.create__ai_list-hairColor {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .create__ai_item {
    padding-right: 24px;
    min-width: auto;
  }

  .create__ai_item-arrow {
    left: 18px;
    right: auto !important;
    z-index: 1;
  }

  .create__ai_item-footer {
    margin: 0;
    padding-right: 0 !important;
    position: absolute;
    left: 0;

  }
}

.create__ai_list-color {
  height: 100%;
  aspect-ratio: 1;
  border-radius: 18px;
  margin-right: 12px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 24px;
  font-family: var(--font-medium);
}

.create__ai_summary {
  width: 100%;
  line-height: 20px;

  .create__ai_item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 3px;
    padding-right: 16px;

    img {
      height: 100%;
      width: auto;
      aspect-ratio: 1;
    }

    .create__ai_item-footer {
      padding: 0 !important;
      margin: 0;
    }
  }

  .create__ai_list-color {
    margin-right: 0;
  }
}

.create__ai_summary-btn {
  width: 50%;
  min-width: 340px;
  z-index: 4;
  margin: 40px auto 0;
}

.create__ai_item-edit {
  opacity: 0.6;
}

.create__ai_summary-color {
  height: 100%;
  width: auto;
  aspect-ratio: 1;
  background-color: #fff;
}
.header__create_ai.with__image {
  .create__ai_steps {
    display: none;
  }
}

.header__create_ai.without__image {
  height: calc(var(--header-height) + 16px);
  padding-bottom: 32px;
  padding-left: 112px;
  padding-right: 112px;

  .header__secondary_left {
    align-self: flex-start;
    top: 8px;
    bottom: auto;
  }

  .header__secondary_right {
    align-self: flex-start;
    bottom: auto;
    top: 0px;
  }

  .create__ai_steps {
    position: absolute;
    left: 24px;
    right: 24px;
    bottom: 16px;
    margin-top: 0;
    padding-bottom: 0;
    width: auto;
    gap: 8px;
  }

  h2 {
    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}



.item__personality_title {
  display: flex;
  gap: 8px;
  padding-right: 32px;
  line-height: 24px;
  align-items: center;
  flex-wrap: nowrap;
}

.create__ai_step-age .create__ai_item {
  position: relative;

  .create__ai_item-footer {
    width: 16px;
    height: 16px;
    margin: 0;
    position: absolute;
    right: 4px;
  }

  span {
    text-align: center;
    display: block;
    width: 100%;
    transition: var(--transition);
  }

  &:hover, &.active {
    span {
      transform: translateX(-6px);
    }

    .create__ai_item-arrow {
      opacity: 1;
    }
  }

}

.step__item_personality {
  align-items: center;

  .create__ai_item-arrow {
    top: auto;
  }

  .create__ai_item-footer {
    padding-right: 0 !important;
    position: absolute;
    right: 0;
  }
}

.step__item_voice-text {
  transition: var(--transition);
}

.create__ai_item.step__item_voice {
  min-width: 175px;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  min-height: auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  padding-right: 32px;

  .create__ai_item-footer {
    position: absolute;
    margin: 0;
    padding: 0 !important;
    right: 8px !important;
  }

  .create__ai_item-arrow {
    position: relative;
    right: auto;
    top: auto;
  }

  .play-btn {
    height: 40px;
    width: 40px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px;
    margin-right: 12px;

    &.active {
      background-color: var(--tint-color);
    }
  }


  .message-voice-container {
    inset: 0;
    right: 0;
    transition: none;
    margin: 4px;
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    opacity: 0;

    .message-wavesurfer {
      width: 100%;
    }
  }

  &:has(.play-btn.active) {
    .step__item_voice-text {
      opacity: 0;

    }

    .message-voice-container {
      opacity: 1;


    }
  }
}

.step__item_voice:hover .step__item_voice-text {
  transform: translateX(-8px);
}

.create__ai_summary .step__item_voice {
  padding: 4px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding-right: 56px;

  .play-btn {
    height: 48px;
    width: 48px;
    min-width: 48px;
    border-radius: 16px;
    margin-right: 8px;
  }

  .step__item_voice-text {
    transform: none;
  }

  .create__ai_item-footer {
    padding-right: 12px !important;
  }
}

.create__upload_image {
  position: relative;
  z-index: 1;
  width: 140px;
  min-width: 140px;
  aspect-ratio: 1;
  display: flex;
  margin-right: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.create__upload_image-left {
  height: 100%;
  overflow: hidden;
  -webkit-mask-image: url('../../assets/icons/upload_bg_left.svg');
  mask-image: url('../../assets/icons/upload_bg_left.svg');
  -webkit-mask-size: cover;
  mask-size: cover;
  flex: 1;
}

.create__upload_image-right {
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-mask-image: url('../../assets/icons/upload_bg_right.svg');
  mask-image: url('../../assets/icons/upload_bg_right.svg');
  -webkit-mask-size: cover;
  mask-size: cover;
  position: relative;
  flex: 1;
  &::before {
    content: '';
    position: absolute;
    left: 3px;
    top: 2px;
    right: 0;
    bottom: 2px;
    background-image: url("../../assets/icons/upload_border_black.svg");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 1;
    animation: 3s createBgBorderUrl 2s linear both;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    right: 100%;
    width: 50%;
    border-right: 1px solid var(--tint-color);
    background: linear-gradient(270deg, rgba(245, 11, 235, 0.4) 0%, rgba(245, 11, 235, 0) 100%);
    animation: scan 2s linear both;
    z-index: 1;
  }

  img {
    position: absolute;
    inset: 0;
  }
}

@keyframes createBgBorderUrl {
  0% {
    opacity: 1;
    background-image: url("../../assets/icons/upload_border.svg");
  }
  30% {
    opacity: 1;
    background-image: url("../../assets/icons/upload_border_black.svg");
  }
  100% {
    opacity: 0;
    background-image: url("../../assets/icons/upload_border_black.svg");
  }
}

@keyframes scan {
  0% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(calc(350% - 2px));
  }
  50% {
    transform: translateX(calc(350% - 2px)) scale(-1, 1);
  }
  55% {
    transform: translateX(calc(350% - 2px)) scale(-1, 1);
  }
  100% {
    transform: translateX(0) scale(-1, 1);
  }
}

.create__upload_image-before {
  position: relative;
  width: 100%;
  height: 100%;
  animation: 1s hide 2s linear both;
}

.create__upload_image-before:after {
  content: '';
  position: absolute;
  inset: 0;
  z-index: 1;
  opacity: 0.4;
  background-color: var(--tint-color);

}

.create__upload_image-after {
  position: absolute;
  inset: 0;
  animation: 1.5s showScale 3s linear both;
  -webkit-mask-image: url('../../assets/icons/upload_bg_right.svg');
  mask-image: url('../../assets/icons/upload_bg_right.svg');
  -webkit-mask-size: cover;
  mask-size: cover;
}

@keyframes showScale {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.create__ai_upload-container {
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 21px;
  transition: var(--transition);
  margin-top: 28px;
  .input__file_trash {
    opacity: 1!important;
  }
}

.create__ai_upload {
  position: relative;
  transition: var(--transition);
  display: flex;
  border-radius: 20px;
  padding: 16px 56px 16px 16px;
  background: #2C2A36;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    background: url("../../assets/images/upload_ellipse_bg.png") no-repeat center center / 100% 100%;
    transform: translate(-120px, 100px);
    pointer-events: none;
    animation: infinityAnim 32s infinite linear;
  }

  p {
    max-width: 300px;
  }

  .input__file:after {
    background: #2C2A36;
  }

  .input__file_trash {
    opacity: 0;
  }

  .input__file_left, .input__file_content, p {
    position: relative;
    z-index: 1;
  }
}



.create__ai_upload-next {
  transition: var(--transition);
  position: absolute;
  min-height: 24px;
  color: var(--tint-color);
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  right: 16px;
  top: 16px;
  background: url("../../../../assets/icons/chevron_active.svg") no-repeat center right / 24px;
  padding-right: 28px;
}

.create__ai_upload-title {
  background: url("../../assets/icons/star.svg") no-repeat top left / 24px;
  padding-left: 28px;
  position: relative;
  z-index: 1;
}

.create__ai_upload-input {
  display: flex;
  .input__file_container {
    z-index: 4;
    position: relative;
  }
}

.create__with_textarea {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.create__with_textarea:has(textarea:focus), .create__with_textarea.visible__textarea {
  .create__with_textarea-content {
    opacity: 0;
    pointer-events: none;
    --max-height: 0;
  }

  .create__ai_textarea {
    top: 0;

    .btn {
      display: flex;
    }
  }

  .create__ai_textarea-clear {
    opacity: 1;
    pointer-events: auto;
  }
}

.create__with_textarea-content {
  transition: var(--transition);

  max-height: var(--max-height);
  overflow: hidden;
  --max-height: 1200px;
}

.create__ai_textarea {
  transition: var(--transition);
  display: flex;
  flex-direction: column;
  align-items: center;
  //padding-bottom: var(--page-padding);
  //position: absolute;
  //left: 0;
  //right: 0;
  //top: 100%;
  padding-top: 16px;

  .btn {
    display: none;
    max-width: 100%;
    width: 344px;
    margin-top: 16px;
  }
}

.create__ai_textarea-clear {
  transition: var(--transition);
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 0;
  color: var(--tint-color);
  font-size: 14px;
  line-height: 20px;
  background: url("../../../../assets/icons/arrow-down-active.svg") no-repeat left center/ 24px;
  padding-left: 28px;
}

.create__ai_upload-container.empty {
  margin-top: 16px;
  &:after {
    opacity: 0;
  }

  .create__ai_upload-next {
    display: none;
  }
}

body:has(.create__ai_page .create__ai_steps-image) {
  .header__secondary {
    height: var(--header-height);
    padding-top: 16px;
    padding-bottom: 16px;

    .create__ai_steps {
      display: none;
    }
  }

  .create__ai_page .create__ai_steps {
    display: flex;

    .create__ai_step.create__ai_step-complete {
      background-color: var(--tint-color);
      border-color: var(--tint-color);
    }

    .create__ai_step.create__ai_step-current {
      border-color: var(--tint-color);
    }

  }
}

.create__ai_upload-label {
  display: none;
  position: absolute;
  inset: 0;
  z-index: 3;
}
.create__ai_upload-container.empty {
  .create__ai_upload-label {
    display: block;
  }
}
.create__ai_upload-container:has(.input__file_wrap.uploading) {
  .create__upload_input, #create__upload_input, .create__ai_upload-label {
    display: none!important;
  }
  .create__ai_upload-title {
    padding: 0!important;
    background: none !important;
  }
}

.create__double_title {
  text-transform: lowercase;
}