.home-page {
  section {
    margin-top: 120px;
  }

  h1 {
    text-transform: uppercase;
    font-size: 48px;
    line-height: 56px;
  }

  h2 {
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  .tab {
    min-width: 156px;
  }
}

.home-top-section {
  margin-top: 24px !important;
  overflow: hidden;

}

.home-top-section-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.home-top-text {
  margin-top: 16px;
  opacity: 0.6;
  font-size: 14px;
  margin-bottom: 40px;

}

.home-top-left {
  position: absolute;
  left: 16px;
  right: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  .gender-select {
    align-self: flex-start;
    z-index: 2;
  }
}

.home-top-right {
  max-width: 532px;
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 56px;
  padding-bottom: 120px;
  //background-color: rgba(7, 5, 19, 0.6);
  //backdrop-filter: blur(20px);

  &:before {
    content: '';
    position: absolute;
    left: -170px;
    right: -200px;
    top: -64px;
    bottom: -40px;
    background: rgba(7, 5, 19, 0.6);
    backdrop-filter: blur(30px);
    z-index: 1;
    -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0, var(--bg-main) 160px, var(--bg-main) 100%);
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0, var(--bg-main) 160px, var(--bg-main) 100%);
    pointer-events: none;
  }

  //&:after {
  //  content: '';
  //  position: absolute;
  //  left: -200px;
  //  right: -200px;
  //  top: -64px;
  //  bottom: -40px;
  //  filter: blur(80px);
  //}
}

.home-top-right-content {
  position: relative;
  z-index: 1;

  .btn-icon {
    min-width: 120px;
  }

  .btn__user-animate {
    width: auto;
    flex: initial;
    align-self: flex-start;

    .btn-content {
      padding-left: 34px !important;
      padding-right: 56px !important;

      span {
        padding-left: 24px;
        text-align: left;
      }

    }
  }
}

.top-carousel {
  align-self: center;
  width: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 32px 0;
  height: 100%;

  &:after {
    content: '';
    position: absolute;
    left: -400px;
    bottom: 0;
    top: 0;
    width: 400px;
    background: linear-gradient(to right, var(--bg-main) 60%, transparent 100%);
    pointer-events: none;
  }

  .top-carousel-item:nth-child(2n) .model-card {
    transform: rotate(5deg);
  }
}


.top-carousel .top-carousel-item.active {
  z-index: 1;

  .model-card {
    transform: rotate(0);
  }

  .model-card-generate {
    opacity: 1;
  }
}

.top-carousel:has(.top-carousel-item:hover) {
  .model-card {
    transform: rotate(-5deg);
  }

  .top-carousel-item:nth-child(2n) .model-card {
    transform: rotate(5deg);
  }

  .top-carousel-item.active:not(:hover) .model-card-chat {
    background: rgba(7, 5, 19, 0.16) !important;
    backdrop-filter: blur(8px);
  }
}


.top-carousel:has(.top-carousel-item:hover) .top-carousel-item:not(:hover) {
  &:before {
    opacity: 0;
  }

  .model-card-about {
    background: transparent;
    backdrop-filter: none;
    padding-bottom: 0;
  }

  .model-card-about-title, .top-carousel-item-avatar, .model-card-about-text, .model-card-chat-text, .model-card-generate, .model-online {
    opacity: 0;
  }

  .model-card-about-welcome {
    opacity: 0;
    transition: var(--transition);
  }

  .btn.model-card-chat {
    width: 10%;
  }

  .top-carousel-item-footer .model-online {
    display: block;
  }

  &:nth-child(2n) .model-card {
    transform: rotate(5deg) !important;
  }

  .model-card {
    transform: rotate(-5deg) !important;
  }
}

.top-carousel-item.active, .top-carousel-item:hover {
  opacity: 1 !important;
  pointer-events: auto;
  z-index: 1;

  &:before {
    opacity: 1;
  }

  .model-card-about {
    padding-bottom: 88px;
    background: rgba(7, 5, 19, 0.6);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }

  .model-card-about-welcome {
    opacity: 1;
  }

  .model-card {
    transform: rotate(0deg) !important;

    .model-online {
      display: block;
    }
  }

  .top-carousel-item-hidden {
    opacity: 1;
  }

  .model-card-chat {
    transition: background-color .3s ease, width .3s ease;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: none;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2) !important;
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.05) !important;
    }
  }

  .top-carousel-item-footer .model-online {
    display: none;
  }

  .model-card-chat-text, .top-carousel-item-avatar, .model-card-generate {
    opacity: 1;
  }
}

.top-carousel-item {
  width: 350px;
  min-width: 350px;
  transition: none;
  position: absolute;
  left: 0;
  transform: translateX(300%);
  opacity: 0;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    left: -28px;
    top: -28px;
    bottom: -28px;
    right: -28px;
    background-color: var(--bg-main);
    filter: blur(80px);
    transition: var(--transition);
    pointer-events: none;
    opacity: 0;
  }

  &.item__position_0 {
    transition: all 2s ease;
    transform: translateX(-100%);
    opacity: 0;
  }

  &.item__position_1 {
    transition: all 2s ease;
    pointer-events: auto;
    transform: translateX(0);
    opacity: 1;
  }

  &.item__position_2 {
    transition: all 2s ease;
    pointer-events: auto;
    transform: translateX(100%);
    opacity: 1;
  }

  &.item__position_3 {
    transition: all 2s ease;
    pointer-events: auto;
    transform: translateX(200%);
    opacity: 1;
  }


  &:nth-child(2n) .model-card {
    transform: rotate(5deg);
  }

  .model-card {
    transform: rotate(-5deg);

    .model-online {
      position: absolute;
      top: 20px;
      right: 21px;
      display: none;
    }
  }

  .model-card-about {
    padding-top: 32px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    &:before {
      display: none;
    }
  }

  .model-card-about-title {
    display: none;
  }

  .model-card-about-welcome {
    opacity: 0;
    transition: var(--transition);
  }

  .model-card-about-text {
    display: none;
  }
}

.top-carousel.without__start_animation {
  .top-carousel-item, .model-card {
    transition: none;
  }
}

.top-carousel-item-about {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px;
}

.top-carousel-item-footer {
  position: relative;
  display: flex;
  margin-top: 8px;
  align-items: center;
}

.top-carousel-item-message {
  position: absolute;
  font-size: 10px;
  font-family: var(--font-bold);
  right: 8px;
}

.top-carousel-item-avatar {
  transition: var(--transition);
  opacity: 0;
  position: absolute;
  left: 25px;
  top: -20px;
  overflow: visible !important;
}

.top-carousel-item-avatar-line {
  position: absolute;
  right: -20px;
  bottom: -5px;
}

.home-top-btn_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;

  .home-top-btn_icon {
    transition: var(--transition);
    opacity: 0.64;
  }
}

.btn:hover .home-top-btn_top .react-icon-arrow {
  opacity: 1;
}

.home-top-footer {
  margin-top: 94px;
  gap: 8px;

  .btn {
    flex: 1;
  }

  .btn-content {
    padding: 16px !important;
    flex-direction: column;
    font-size: 16px;
    line-height: 24px;
    align-items: flex-start;
  }
}

.home-faq {
  width: 50%;
  padding-right: 12px;
}


.home-faq-item {
  position: relative;
  /*background: rgba(255, 255, 255, 0.05);*/
  background: var(--bg-secondary);
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
  margin-top: 16px;
  transition: var(--transition);

  &:hover {
    background: var(--bg-secondary-hover);

    .home-faq-item-head {
      opacity: 1;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    background: var(--gradient);
    z-index: -1;
    border-radius: 34px;
    transition: var(--transition);
    opacity: 0;
  }
}

.home-faq-item.active {
  background: var(--bg-secondary);

  &:before {
    opacity: 1;
  }

  .home-faq-item-head {
    opacity: 1;

    .react-icon {
      transform: rotate(180deg);
    }
  }

  .home-faq-item-content {
    transition: opacity .3s ease;
    transform: scale(1);
    opacity: 1;
    max-height: 200vh;
    display: block;
    margin-top: 0;
    padding: 0 32px 24px;
  }
}

.home-faq-item-head {
  transition: var(--transition);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 24px 32px;
  opacity: 0.8;
  position: relative;
  z-index: 1;
}

.home-faq-item-head .react-icon {
  transition: var(--transition);
}

.home-faq-item-content {
  transform: scale(0);
  opacity: 0;
  max-height: 0;
  font-size: 16px;
  line-height: 24px;
  //color: var(--muted-color);

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: var(--gradient);
    border-radius: 360px;
    filter: blur(120px);
    z-index: -1;
    pointer-events: none;
  }

  ol {
    margin-top: 8px;
    font-size: 14px;
    padding-left: 12px;
    line-height: 20px;

    li {
      margin-top: 4px;
    }
  }
}

.home-description {
  width: 50%;
  padding-left: 12px;

  h5 {
    padding: 24px;
    padding-bottom: 0;
  }
}

.home-description-container {
  background: var(--bg-secondary);
  border-radius: 32px;
  margin-top: 32px;
  overflow: hidden;
  padding: 24px;
}

.home-footer-section {
  padding-top: 0;
}

.home-footer {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  //background: linear-gradient(to top ,rgba(7, 5, 19, 0.6) 0%, var(--bg-main) 100%);
  padding-bottom: 48px;
  margin-left: -24px;
  margin-bottom: -24px;
  margin-right: -24px;

  //backdrop-filter: blur(40px);
  &:before {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0.16);
    left: -80px;
    bottom: -80px;
    right: -80px;
    height: 290px;
    filter: blur(60px);
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    background: rgba(7, 5, 19, 0.6);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    filter: blur(60px);
    z-index: -1;
  }

  h2 {
    text-align: left !important;
  }

}

.home-footer-logo {
  height: 36px;
  margin-bottom: 24px;
}

.home-footer-payment {
  height: 64px;
  border: 1px solid var(--border-color);
  width: 112px;
  margin-left: 8px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;

  img {
    height: 100%;
  }
}

.home__footer_bottom {
  margin: 24px -24px 0;
  padding: 10px 10px 10px 24px;
  align-items: center;
  display: flex;
  background-color: var(--bg-main);
  justify-content: space-between;
  border-radius: 16px;
}

.home-models {
  //overflow: hidden;
  z-index: 1;
  margin-top: 0px !important;
}

.home-models-list {
  margin-top: 24px;
}


.top-carousel-item-message-name {
  font-size: 12px;
  line-height: 18px;
  font-family: var(--font-montserrat-bold);
  margin-bottom: 8px;
  display: none;
}

.home-top-gender {
  display: flex;
  justify-content: flex-start;
}

.home-footer-contacts {
  margin-top: 48px;

  &.bottom {
    display: none;
  }
}

.home-page.visible {
  header .gender-select, .home__btn {
    opacity: 1;
    pointer-events: auto;
  }
}

.home__btn {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 20;
  margin: 0;
  align-self: flex-end;
  transition: all 1s ease;
  opacity: 0;
  pointer-events: none;
}

.home-footer-info {
  .btn {
    flex: initial;
  }


  .btn__user_animate-icon {
    margin-right: 20px;
  }
}

.home-footer-nav {
  .form-group {
    width: auto;
    align-items: flex-start;
    background-color: transparent;
  }

  .form-control-wrap {
    margin-left: -16px;
  }

  .form-control {
    min-height: auto;
    border: none;
    font-size: 16px;

    background-color: transparent;

    option {
      font-size: 14px;
      padding-left: 12px !important;
    }
  }
}

.carousel__item_footer-message {
  display: none;
  font-size: 12px;
  line-height: 18px;
  color: var(--tint-color);
}

.home__footer_info-btns {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.home-page {
  .home-top-right-content .btn-secondary {
    opacity: 0;
    width: 0;
    min-width: 0;
    margin: 0 !important;
    pointer-events: none;
    display: none;
  }

  .home-top-footer .btn-outline-secondary {
    opacity: 0;
    pointer-events: none;
    margin-top: 8px;
  }

  .home-footer-nav a:last-child {
    display: none;
  }
}

.home-page.home__page_ready-1.home__page_sub-0 {
  .home-top-right-content .btn-secondary {
    pointer-events: auto;
    display: flex;
    opacity: 1;
    width: 88px;
    min-width: 88px;
  }

  .home-top-footer .btn-outline-secondary {
    opacity: 1;
    display: flex;
    pointer-events: auto;

  }

  .home-footer-nav a:last-child {
    display: flex;
  }
}

.home__footer_bottom-right {
  display: flex;
  gap: 24px;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  justify-content: flex-end;
}

.home__footer {
  display: flex;
  gap: 24px;
  align-items: flex-start;
}

.home__footer-btns {
  display: flex;
  align-items: flex-end;
  gap: 8px;

  .btn {
    max-width: 320px;
    flex: 1;
  }

  .btn-content {
    padding-left: 40px !important;
  }

  .form-group {
    width: auto;
    min-width: 220px;
  }
}


.top__carousel_mobile {
  width: 100%;
  height: 100%;
  align-items: center;
  display: none;
  position: relative;
  //&:before {
  //  pointer-events: none;
  //  content: '';
  //  position: absolute;
  //  left: -10px;
  //  top: 0;
  //  bottom: 0;
  //  width: 150px;
  //  background: linear-gradient(to right, var(--bg-main) 0%, transparent 100%);
  //  z-index: 2;
  //}
  //&:after {
  //  pointer-events: none;
  //  content: '';
  //  position: absolute;
  //  right: -10px;
  //  top: 0;
  //  bottom: 0;
  //  width: 150px;
  //  background: linear-gradient(to left, var(--bg-main) 0%, transparent 100%);
  //  z-index: 2;
  //}

  .top-carousel-item {
    margin: 0 auto;
    transition: var(--transition) !important;
    position: relative;
    transform: none !important;
    opacity: 1 !important;
    pointer-events: auto !important;

    .model-card {
      transform: none !important;

      img {
        border-radius: 4px 4px 24px 4px;
      }
    }

  }

  .top-carousel-item-footer {
    opacity: 0 !important;
    transition: var(--transition);
  }

  .swiper-slide-active {
    .top-carousel-item-footer {
      opacity: 1 !important;
      pointer-events: none;
    }
  }

  .swiper-slide {
    opacity: 0;
    display: flex;
    justify-content: center;
  }

  .swiper-slide-prev, .swiper-slide-next, .swiper-slide-active {
    opacity: 1;
  }

  .swiper-slide-prev .top-carousel-item {
    transform: rotate(-5deg) !important;
  }

  .swiper-slide-next .top-carousel-item {
    transform: rotate(5deg) !important;
  }
}

.home__footer_18 {
  background: rgba(255, 255, 255, 0.1);
  color: var(--tint-color);
  height: 48px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  cursor: pointer;
}

.create__ai_homeList-item {
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  color: #fff!important;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 230px;
    background: linear-gradient(360deg, rgba(245, 11, 235, 0.6) 0%, rgba(245, 11, 235, 0) 92.61%);
    pointer-events: none;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 134px;
    background: linear-gradient(0deg, rgba(245, 11, 235, 0.8) 0%, rgba(245, 11, 235, 0) 100%);
    pointer-events: none;
  }
  &:hover {
    .create__ai_homeList-border {
      border-width: 16px;
    }
    .create__ai_homeList-text {
      transform: translateY(-4px);
    }
    .react-icon {
      opacity: 1;
    }

    .btn__user_animate-icon-ellipse {

      &:first-child {
        transform: rotate(180deg) translateY(-12px);
      }

      &:nth-child(2) {
        transform: translateY(-12px);
      }
    }
  }
}
.create__ai_homeList-border {
  position: absolute;
  pointer-events: none;
  inset: 0;
  border: 12px solid var(--tint-color);
  border-radius: 4px 4px 40px 4px;
  filter: blur(30px);
  transition: var(--transition);
}
.create__ai_homeList-text {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 30px;
  text-align: center;
  font-family: var(text-medium), sans-serif;
  font-size: 24px;
  line-height: 36px;
  max-width: 184px;
  transition: var(--transition);
}