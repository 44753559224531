.tg__app {
  .chat-board {
    padding-bottom: 104px;
    &::-webkit-scrollbar-track {
      margin-bottom: 120px ;
    }
  }
  .chat__control_wrap {
    height: 64px;
    animation: chat__btn_animate 3s infinite both;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 24px;
    z-index: 1;
    &:before {
      background: rgba(255, 222, 103, 0.6);
    }
    .btn {
      height: 100%;
      min-height: 100%;
      background: #FFDE67;
      color: var(--bg-main);
      .btn-content {
        min-height: 100%;
        height: 100%;
      }
      .btn-icon-left {
        left: 20px;
      }
    }
  }
}

@keyframes chat__btn_animate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9, 0.95);
  }
  100% {
    transform: scale(1);
  }
}