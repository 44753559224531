.avatar {
  width: 56px;
  height: 56px;
  min-width: 56px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  svg {
    width: 32px;
    height: 32px;
  }

  &.xxl {
    width: 120px;
    height: 120px;
    min-width: 120px;

    &.user {
      svg {
        width: 80px;
        height: 80px;
      }
    }
    .user__icon_secondary svg {
      width: 70px;
      transform: translateY(8px);
      height: auto;
    }
  }

  &.xl {
    width: 88px;
    height: 88px;
    min-width: 88px;

    &.user {
      svg {
        width: 56px;
        height: 56px;
      }
    }
  }

  &.lg {
    width: 72px;
    height: 72px;
    min-width: 72px;

    &.user {
      svg {
        width: 48px;
        height: 48px;
      }
    }
  }

  &.sm {
    width: 48px;
    height: 48px;
    min-width: 48px;

    &.user {
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }

  &.xs {
    width: 40px;
    height: 40px;
    min-width: 40px;

    &.user {
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .user__icon_secondary svg {
      width: 24px;
      transform: translateY(4px);
      height: auto;
    }
  }

  &.xxs {
    width: 32px;
    height: 32px;
    min-width: 32px;

    &.user {

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    object-position: center !important;
    border-radius: 50% !important;
    position: relative !important;
  }


}