@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  :root {
    --header-height: 72px;
    --page-padding: 16px;
    --model-border-radius: 0 24px 0 0;
  }
  main {
    padding-left: 0 !important;
    padding-top: calc(var(--header-height) + 16px);
  }
  .page-title {
    display: none;
  }
  section {
    margin-top: 60px;
  }
  .pagination-wrap {
    margin-top: 16px;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .btn-expand {
    min-width: 40px;
    width: 40px;
    height: 40px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
  .btn-trash {
    min-width: 40px;
    width: 40px;
    height: 40px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
  .slick-arrow {
    width: 48px;
    height: 48px;
    border-radius: 16px;
  }
  body:has(.chat-page) {
    overflow: hidden;
    height: 100dvh;
  }

  .tabs {
    min-height: 48px;
    height: 48px;
    border-radius: 16px;
  }
  .tab {
    flex: 1;
    justify-content: center;
    padding: 0 20px;
    -webkit-border-radius: 13px;
    -moz-border-radius: 13px;
    border-radius: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-expand {
    left: auto;
    right: 8px;
    top: 8px;
    transform: none;
  }
  section {
    margin-top: 32px;
  }
  .container {
    padding-left: 16px !important;
    padding-right: 16px !important;
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 575px) {
}


/* touchscreens */
@media (hover: none) {
  a, .link {
    //text-decoration: underline;
  }
  .horizontal-scroll-arrow {
    display: none !important;
  }
  .horizontal-scroll {
    overflow-x: auto !important;
  }
  .btn-expand-container .btn-expand {
    opacity: 0;
  }
  body {
    width: 100vw;
    overflow-x: hidden;
  }
  .model-profile-head .btn-expand {
    display: none !important;
    opacity: 0;
  }
}

/* mouse */
@media (hover: hover) {
  /* ... */
}