
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;

}

::-webkit-scrollbar-thumb {
  background: var(--bg-secondary);
  border-radius: 4px;
  margin-left: 4px;
}

* {
  outline: none;
}

html {
  scroll-behavior: smooth;
  //overflow-x: hidden;
}


body {

  //font-size: clamp(14px, 2vw, 16px);
  //line-height: clamp(20px, 2vw, 24px);
  overflow-x: hidden;
  font-family: var(--font-regular);
  font-size: 16px;
  line-height: 24px;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-main);
  font-display: swap;
  color: var(--text-color);
  cursor: default;

}


h1, h2, h3, h4, h5, h6, p {
  margin-bottom: 0;
}

p {
  font-size: 14px;
  line-height: 20px;
  user-select: auto;
}

h1 {
  position: relative;
  font-family: var(--font-medium);
  text-wrap: balance;
  font-size: 40px;
  line-height: 48px;
}

h2 {
  font-family: var(--font-medium);
  font-size: 40px;
  line-height: 48px;
}

h3 {
  font-family: var(--font-medium);
  font-size: 32px;
  line-height: 40px;
}

h4 {
  font-family: var(--font-medium);
  font-size: 24px;
  line-height: 32px;
}

h5 {
  font-family: var(--font-medium);
  font-size: 16px;
  line-height: 25px;
}


#root, main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

main {
  transition: var(--transition);
  overflow-x: hidden;
  padding-top: var(--header-height);
  padding-bottom: var(--page-padding);
}

nav {
  user-select: none;
}

section {
  position: relative;
}

.flex-1 {
  flex: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-move {
  cursor: move;
}

.cursor-help {
  cursor: help;
}

.text-muted {
  color: var(--muted-color) !important;
}

.text-obscure {
  color: rgba(255, 255, 255, 0.1) !important;
}

.text-muted-14 {
  color: var(--muted-color);
  font-size: 14px;
  line-height: 20px;
}

.text-success {
  color: var(--success) !important;
}
.text-danger {
  color: var(--danger) !important;
}

.text-danger-14 {
  color: var(--danger);
  font-size: 14px;
  line-height: 20px;
}

.text-dark {
  color: var(--dark-color) !important;
}

.text-dark-14 {
  color: var(--dark-color);
  font-size: 14px !important;
  line-height: 20px;
}

.text-24 {
  font-size: 24px;
  line-height: 36px;
}

.text-bold {
  font-family: var(--font-bold);
}

.text-bold-14 {
  font-family: var(--font-bold);
  font-size: 14px;
  line-height: 20px;
}

.text-bold-12 {
  font-family: var(--font-bold);
  font-size: 12px;
  line-height: 18px;
}

.text-regular {
  font-family: var(--font-regular);
}

.text-medium {
  font-family: var(--font-medium);
}

.text-medium-14 {
  font-family: var(--font-medium);
  font-size: 14px;
  line-height: 20px;
}

.text-medium-12 {
  font-family: var(--font-medium);
  font-size: 12px;
  line-height: 18px;
}

.text-semibold {
  font-family: var(--font-semibold);
}

.text-semibold-14 {
  font-family: var(--font-semibold);
  font-size: 14px;
  line-height: 20px;
}

.text-14 {
  font-size: 14px;
  line-height: 20px;
}

.text-12 {
  font-size: 12px;
  line-height: 18px;
}

a, .link, .text-active {
  cursor: pointer;
  color: #fff;
  transition: var(--transition);
  text-decoration: none;

  &:hover {
    color: var(--tint-color);
  }
}

.text-accent {
  color: var(--tint-color);
}

.text-tint {
  color: var(--secondary-color);
}


.react-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.bg-16 {
  background: rgba(255, 255, 255, 0.16);
}

.bdrs-12 {
  border-radius: 12px !important;
}

.bdrs-24 {
  border-radius: 24px !important;
}

.bdrs-32 {
  border-radius: 32px !important;
}


.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.page-title {
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  .react-icon {
    margin-right: 8px;
  }
}

.card {
  background: rgba(255, 255, 255, 0.08);
  color: var(--text-color);
}

.card-dark {
  background: rgba(18, 18, 18, 0.4);
  border-radius: 32px;
  color: var(--text-color);
}


.form-radio-custom {
  width: 24px;
  min-width: 24px;
  height: 24px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background-color: var(--bg-main);
  border-radius: 50%;
  position: relative;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    border: none;
    background: var(--gradient);

    &:after {
      opacity: 1;
    }
  }

  &:after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 20px;
    opacity: 0;
    pointer-events: none;
    transition: var(--transition);
    background: #fff;
  }
}

.letter-uppercase::first-letter {
  text-transform: uppercase;
}

.slick-dots {
  position: relative;
  bottom: 0;
  margin-top: 4px;
}

.slick-dots li {
  width: auto;
  height: auto;
  margin: 0 4px;
}

.slick-dots li button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

.slick-dots li button:before {
  content: '';
  width: 8px;
  height: 8px;
  background: rgba(255, 255, 255, 0.16);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  background-color: #fff;
}

.slick-arrow {
  top: 50%;
  transform: translateY(-80%);
  background: rgba(255, 255, 255, 0.1);
  width: 64px;
  height: 64px;
  border-radius: 24px;
  transition: var(--transition);
  cursor: pointer;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  opacity: 1;
  z-index: 2;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  &:active {
    background: rgba(255, 255, 255, 0.05);
  }

  &:before {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    opacity: 1;
    pointer-events: none;
  }
}

.slick-disabled {
  pointer-events: none;
  background: rgba(255, 255, 255, 0.04) !important;
}

.slick-next {
  right: 16px;

  &:before {
    background: url("../icons/chevron_right.svg") no-repeat center center;
  }
}

.slick-prev {
  left: 16px;

  &:before {
    background: url("../icons/chevron_left.svg") no-repeat center center;
  }
}

hr {
  opacity: 0.1;
  margin: 24px 0;
}


.Toastify__toast {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 16px;
  background: var(--bg-main);
  padding: 16px;
  font-family: var(--font-regular);

  .react-icon svg {
    width: 20px !important;
    height: 20px !important;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    background: rgba(255, 255, 255, 0.1);
    display: none;
  }
}

.Toastify__progress-bar {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  height: 4px;
  background: var(--gradient);
}

.Toastify__toast--error {
  background: #562131;
}

.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.popover {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
}

.popover.visible {
  .popover-panel-container {
    display: block;
  }
}

.popover-panel-container {
  position: absolute;
  top: 100%;
  right: 0;
  padding-top: 4px;
  display: none;
}

.popover-panel {
  padding: 4px;
  background: rgba(7, 5, 19, 0.6);
  border: 1px solid var(--border-color);
  border-radius: 20px;
  backdrop-filter: blur(16px);
}

.popover-panel-item {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 12px;
  padding-right: 20px;
  transition: var(--transition);
  cursor: pointer;
  border-radius: 16px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
    color: #fff;
  }
}

b {
  font-family: var(--font-bold);
}


.dot-separator {
  width: 4px;
  height: 4px;
  min-width: 4px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: #fff;
  margin: 0 8px;
}

.pre-wrap {
  white-space: pre-wrap;
}

.tabs {
  //background: ;
  border-radius: 24px;
  padding: 2px;
  display: inline-flex;
  border: 1px solid rgba(255, 255, 255, 0.16);
  height: 64px;
  min-height: 64px;
}

.tab {
  border-radius: 22px;
  padding: 0 32px;
  display: grid;
  align-items: center;
  cursor: pointer;
  color: #fff;
  white-space: nowrap;
  transition: var(--transition);
  flex: 1;
  justify-content: center;
  max-height: 100%;
  user-select: none;

  &:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.active {
    background: var(--secondary-color);
    color: var(--bg-main);
  }
}

.tabs.sm {
  height: 40px;
  padding: 0;
  font-size: 14px;

  .tab {
    padding: 8px 16px;
  }
}

.empty-list {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--dark-color);
  text-align: center;
  padding-bottom: 100px;
}

.empty-list-content {
  filter: grayscale(100%);
  background: url("../images/empty.png") no-repeat center top/ 40px;
  padding-top: 56px;
  margin-top: 40px;
  min-width: 200px;
}

.preload-hidden-images {
  position: absolute;
  opacity: 0;
  display: none;

  img {
    width: 10px;
    height: 10px;
  }
}
.lazy-load-image-background {
  height: 100%;
  width: 100%;
  border-radius: inherit;
}
.preload-image {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  object-fit: contain;

  & + img {
    position: relative;
  }
}

.model-online {
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background-color: var(--tint-color);
  margin-left: 6px;
}

.btn-expand-container {
  user-select: none;
  position: relative;
  &:hover .btn-expand {
    opacity: 1;
  }
}

.btn-expand {
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  transition: var(--transition);
}

.btn-trash-container {
  position: relative;
  user-select: none;

  &:hover .btn-trash {
    opacity: 1;
  }
}

.btn-trash {
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 16px;
  top: 12px;
  right: 12px;
  background: rgba(227, 63, 113, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  opacity: 0;
  z-index: 1;
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    background: rgba(227, 63, 113, 0.32);
  }

  &:active {
    background: rgba(227, 63, 113, 0.08);
  }
}


.btn-edit {
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 64px;
  -moz-border-radius: 64px;
  border-radius: 64px;
  width: 32px;
  height: 32px;
  background: url("../icons/edit_active.svg") no-repeat center center, #fff;
}

.card__active_16 {
  border: 1px solid var(--border-color);
  padding: 16px;
  border-radius: 16px;
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    border-color: #fff;
  }
}

.animate__show {
  animation: show 3.5s;
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blur__bg_container {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    border-radius: 40px;
    left: 0;
    top: 48px;
    bottom: 24px;
    right: 0;
    background: rgba(255, 255, 255, 0.1);
    filter: blur(40px);
    pointer-events: none;
  }
}


.btn.btn__user-animate {
  flex: 1;

  &:hover {
    .react-icon {
      opacity: 1;
    }

    .btn__user_animate-icon-ellipse {
      &:first-child {
        transform: rotate(180deg) translateY(-12px);
      }

      &:nth-child(2) {
        transform: translateY(-12px);
      }
    }
  }

  &.btn-lg {
    .btn-content {
      padding-left: 32px;
      padding-right: 56px;
    }
  }

  &.btn-md {
    .btn-content {
      padding-left: 16px;
      padding-right: 32px;
    }
  }
}

.btn__user_animate-icon-ellipse {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btn__user_animate-icon {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .react-icon {
    transition: var(--transition);
    opacity: 0;

    svg {
      height: 24px !important;
      width: auto !important;
    }
  }

  .btn__user_animate-icon-ellipse {
    position: absolute;
    transition: var(--transition);

    &:first-child {
      transform: rotate(180deg);
    }
  }
}

.blur-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blur-content {
  filter: blur(10px);
  pointer-events: none;
}

.btn.blur-btn {
  position: absolute;
  z-index: 1;

  .btn-content {
    //padding-left: 12px!important;
    padding: 0 24px 0 12px !important;
    white-space: pre-wrap;
    text-align: center;
  }

  .btn-icon-left {
    font-size: 14px;
    margin-left: 0;
    margin-right: 0;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}
.__floater {
  padding: 0!important;
}
.__floater__arrow {
  display: none;
}
.react-joyride__spotlight {
  border-radius: 20px!important;
}
.tooltip__body {
  margin-right: 48px;
  background-color: var(--tint-color);
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  position: relative;
  padding: 16px 12px 12px;
  font-size: 12px;
  line-height: 18px;
  .btn {
    min-height: 32px!important;
    height: 32px!important;
    border-radius: 12px!important;
  }
}


.play-btn {
  position: relative;
  cursor: pointer;
  width: 32px;
  min-width: 32px;
  height: 32px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.16);
  &:hover {
    background: rgba(255, 255, 255, 0.24);
  }
  &.accent {
    background: var(--tint-color);
    &.active {
      background: #F630EE;
    }
    &:hover {
      background: #F630EE;
    }
  }

}


@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}