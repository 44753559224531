.model-card {
  flex: 1;
  width: 100%;
  border-radius: var(--model-border-radius);
  position: relative;
  aspect-ratio: 1/1.42;
  display: flex;
  flex-direction: column;
  user-select: none;
  transition: var(--transition);
  //overflow: hidden;

  &:hover {
    .model-card-about {
      background-color: rgba(7, 5, 19, 0.6);
      backdrop-filter: blur(20px);

      .name-age {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .model-card-generate {
      opacity: 1;
    }

    .model-card-chat {
      width: 100%;
      background-color: var(--tint-color);
      justify-content: flex-start;

      &:hover {
        background-color: #F630EE;
      }

    }

    .model-card-chat-text {
      opacity: 1;
    }

    .model-card-about-arrow {
      opacity: 1;
    }
    .model-card-category {
      background: rgba(12, 12, 12, 0.6);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: var(--model-border-radius);
  }
}

.model-card:has(.model-card-controls):hover {
  .model-card-about {
    padding-bottom: 88px;
  }
}

.model-card.info-visible {
  .model-card-about-arrow {
    transform: rotate(180deg);
  }
}

.model-card:hover.info-visible {
  .model-card-about-text {
    max-height: 60px;
    padding-top: 12px;
    opacity: 1;
  }
}


.model-card-category {
  transition: var(--transition);
  position: absolute;
  text-align: center;
  top: 4px;
  left: 4px;
  height: 32px;
  align-self: center;
  padding: 0 16px;
  border-radius: 0 16px 16px 16px;
  background: rgba(12, 12, 12, 0.16);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 1;
}

.model-card-about {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 24px 24px 20px;
  transition: var(--transition);
  border-radius: 32px 32px 32px 4px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: -1px;
    right: 0;
    border-radius: 0 0 32px 4px;
    background: linear-gradient(to top, rgba(7, 5, 19, 0.8) 0%, rgba(7, 5, 19, 0) 100%);
  }

  .name-age {
    line-height: 48px;
    transition: var(--transition);
  }
}

.model-card-chat-btn-mobile {
  display: none;
}

.model-card-controls {
  position: absolute;
  display: flex;
  bottom: 24px;
  left: 24px;
  right: 24px;
  justify-content: flex-end;
}

.model-card-generate {
  opacity: 0;
  margin-right: 8px;
  svg {
    width: 20px!important;
    height: 20px !important;
  }
}

.btn.model-card-chat {
  background-color: rgba(7, 5, 19, 0.16);
  backdrop-filter: blur(8px);
  transition: var(--transition);
  width: 10%;
  justify-content: flex-start;
  padding: 0 14px;
}

.model-card-chat-text {
  transition: var(--transition);
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.model-card-about-text {
  opacity: 0;
  transition: var(--transition);
  max-height: 0;
}

.model-card-about-arrow {
  transition: var(--transition);
  transform: rotate(0deg);
  opacity: 0;
  margin-left: 8px;
  cursor: pointer;
  z-index: 1;
}

.model-card-about-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
