@media only screen and (min-width: 992px) {
  .create__ai_list {
    .col-4, .col-6 {
      min-width: 20% !important;
      width: 20% !important;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .create-ai-page {
    .page-title {
      display: none;
    }
  }

}

@media only screen and (max-width: 991px) {
  .create__ai_page {
    .gender-select, .create__ai_steps {
      display: none;
    }
  }

  .create__ai_title {
    margin-top: 8px;
    min-height: 56px;

    h4 {
      font-size: 16px;
      line-height: 24px;
    }

    .btn {
      min-width: 56px;
      width: 56px;
      height: 56px;
      min-height: 56px;
    }
  }
  .create__ai_summary {

  }
  .create__ai_page:has(.create__ai_summary-btn) {
    padding-bottom: 80px;
  }
  .create__ai_summary-btn {
    position: fixed;
    bottom: calc(var(--footer-height) + 16px);
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .generate-image-modal_models {
    padding-top: 40px;
    padding-bottom: 0!important;
  }
  .create__ai_list {
    padding-bottom: 16px;
    gap: 8px;

    &.row {
      gap: 0px;
    }
  }
  .create__ai_steps-container {
    margin: -16px -16px 8px;
    padding-top: 16px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    border-radius: 32px !important;
    border: none !important;
    width: 100vw;

    .input__file_trash {
      right: 8px;
    }
  }
  .create__ai_upload-container:hover {
    border-color: var(--border-color);
  }
  .create__ai_upload-container.empty {

    .input__file_content {
      color: var(--tint-color);
    }
  }
  .create__ai_upload {
    padding-right: 16px;
  }
  .create__ai_steps-image {
    padding-right: 54px;
  }
  .create__ai_upload-next {
    opacity: 0.6 !important;
    background-image: url("../../../../assets/icons/chevron_right.svg");
  }

  .create__double_title {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .create__ai_upload-title {
    padding-top: 28px;
    padding-left: 0;
  }
  .create__ai_style .create__ai_item {
    img {
      border-radius: 12px;
      width: 100px;
    }

    .text-24 {
      font-size: 20px;
    }
  }
  .create__ai_style .create__ai_item-arrow {
    right: 16px;
    top: 16px;
  }
  .create__ai_item {
    padding: 12px;
    min-height: 48px;
    border-radius: 24px;

    &:before {
      border-radius: 24px;
    }
  }
  .create__ai_item-arrow {
    right: 12px;
    top: 12px;
  }
  .create__upload_image {
    width: 100px;
    min-width: 100px;
    height: 100px;
  }
  .create__ai_upload-container {

    &.empty {
      .input__file .input__file_left {
        display: none;
      }
    }

    p {
      display: none;
    }

    .input__file_left {
      width: 20px;
      height: 20px;
      min-width: 20px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;

      img {
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
      }
    }

    .create__ai_upload-input {
      margin-top: 4px;
    }

    .input__file_wrap {
      min-height: 20px;
      justify-content: flex-start;

      :before, :after {
        display: none;
      }
    }

    .input__file_content {
      color: var(--dark-color);
    }

    .input__file_container {
      max-width: 100%;
      justify-content: flex-start;
      display: inline-flex;
    }

    .input__file {
      border-radius: 6px;
      width: auto;
      padding: 0;
      border: none;
    }

  }

}

@media only screen and (max-width: 575px) {
  .create__ai_upload {
    font-size: 14px;
    line-height: 20px;
  }
  .create__ai_steps {
    gap: 8px;
  }
  .create__ai_steps-image .text-dark-14 {
    font-size: 12px !important;
    line-height: 18px;
  }


  .create__ai_summary-btn {
    left: 16px;
    transform: none;
    right: 16px;
    width: auto;
  }
  .item__personality_title {
    align-items: flex-start;
    gap: 4px;
  }
  .create__ai_item p {
    font-size: 12px;
    line-height: 18px;
  }
  .create__ai_list-hairColor {
    justify-content: flex-start;
  }
  .create__ai_list-hairColor .create__ai_item {
    min-width: calc(50% - 16px);
    width: calc(50% - 16px) !important;
    justify-content: flex-start;
    padding-right: 16px;
  }
  .create__ai_summary-btn {
    min-width: auto;

    .btn-content {
      padding-left: 56px;
      padding-right: 32px;
    }
  }
}

/* touchscreens */
@media (hover: none) {
  .create__ai_upload .input__file_trash {
    opacity: 1;
  }
  .create__ai_upload-next {
    opacity: 1;
    width: 24px;
    padding: 0;

    span {
      display: none;
    }
  }
  .create__ai_steps-image {
    .input__file_left-asset:after {
      width: 24px;
      height: 24px;
      right: 4px;
      bottom: 4px;
      background: url("../../../../assets/icons/refresh.svg") no-repeat center center / 12px, rgba(7, 5, 19, 0.24);

    }
  }
}

/* mouse */
@media (hover: hover) {
  /* ... */
  .create__ai_item:hover {
    border-color: var(--tint-color);

    &:before {
      opacity: 1;
    }

    .create__ai_item-arrow, .create__ai_item-edit {
      opacity: 1 !important;
    }

    .create__ai_item-footer {
      //max-width: 0%;
      padding-right: 24px;
      padding-left: 8px !important;
      text-align: left;
      align-items: flex-start;
      //position: relative;
    }
  }
  .create__ai_list-age .create__ai_item {
    span {
      transform: translateX(-6px);
    }

    .create__ai_item-arrow {
      position: relative;
      opacity: 1;
    }
  }
  .create__ai_item.small:hover {
    .create__ai_item-footer {
      padding-right: 24px;
      padding-left: 8px;
    }
  }
}
