
@font-face {
    font-family: 'Poppins-Regular';
    src: local('Poppins Regular'), local('Poppins-Regular'), url('../fonts/Poppinsregular.woff2') format('woff2'), url('../fonts/Poppinsregular.woff') format('woff'), url('../fonts/Poppinsregular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins-SemiBold';
    src: local('Poppins Poppins-BoldSemiBold'), local('Poppins-SemiBold'), url('../fonts/Poppinspoppins-boldsemibold.woff2') format('woff2'), url('../fonts/Poppinspoppins-boldsemibold.woff') format('woff'), url('../fonts/Poppinspoppins-boldsemibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins-Back';
    src: local('Poppins Black'), local('Poppins-Black'), url('../fonts/Poppinsblack.woff2') format('woff2'), url('../fonts/Poppinsblack.woff') format('woff'), url('../fonts/Poppinsblack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins-Medium';
    src: local('Poppins Poppins-SemiBoldMedium'), local('Poppins-Medium'), url('../fonts/Poppinspoppins-semiboldmedium.woff2') format('woff2'), url('../fonts/Poppinspoppins-semiboldmedium.woff') format('woff'), url('../fonts/Poppinspoppins-semiboldmedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins-Bold';
    src: local('Poppins Bold'), local('Poppins-Bold'), url('../fonts/Poppinsbold.woff2') format('woff2'), url('../fonts/Poppinsbold.woff') format('woff'), url('../fonts/Poppinsbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}