footer {
  position: fixed;
  z-index: 199;
  left: 0;
  right: 0;
  bottom: -1px;
  height: var(--footer-height);
  padding-top: 16px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(16px);
  display: none;
  border-radius: 32px 32px 0 0;

  nav {
    width: 100%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-around;
    }

    .menu-item {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      opacity: 0.6;
      cursor: pointer;
      transition: var(--transition);
      width: 24px;
      height: 50px;
      position: relative;
      &:hover {
        color: #fff;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 4px;
        height: 4px;
        border-radius: 8px;
        background-color: var(--tint-color);
        transition: var(--transition);
        opacity: 0;
      }

      &.active, &:hover {
        opacity: 1;
      }

      &.active:before {
        opacity: 1;
      }

      .react-icon {
        margin: 0 !important;
        width: 24px;
        height: 24px;
      }
    }

    .menu-item-text {
      position: absolute;
      top: 30px;
      font-size: 10px;
      line-height: 10px;
      width: 100px;
      max-width: 18vw;
      text-align: center;
      display: grid;
      overflow: hidden;
    }
  }
}

@media only screen and (max-width: 991px) {
  footer {
    display: flex;
  }
  main {
    padding-bottom: calc(var(--footer-height) + 17px);
  }
}

@media only screen and (max-width: 1199px) {

}


/* mouse */
@media (hover: hover) {
}