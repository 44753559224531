.skeleton {
  position: relative;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  width: 100%;

  &:after {
    content: '';
    width: 120%;
    position: absolute;
    background:  rgba(255, 255, 255, 0.1);
    height: 40px;
    filter: blur(10px);
    animation: skeleton-anim 2s infinite linear;
    transform:  rotate(-5deg);
  }
}
.skeleton-model {
  aspect-ratio: 1/1.42;
  border-radius: var(--model-border-radius);
}
.skeleton-nav {
  height: 88px;
  border-radius: 16px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}


@keyframes skeleton-anim{
  0% {
    top: -40px;
  }
  20% {
      top: calc(100% + 40px);
  }
  100% {
    top: calc(100% + 40px);
  }
}

@media (hover: none) or (max-width: 991px) {
  .skeleton-nav {
    height: 48px;
  }
}