@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .form-control {
    min-height: 64px;
    border-radius: 20px;
    &::placeholder {
      font-size: 14px !important;
    }
  }
  .form-control-wrap {
    border-radius: 20px;
  }
  .form-control-icon {
    padding-left: 56px;
  }
  .input-icon {
    left: 20px;
    width: 24px;
    height: 24px;
  }
  .form-control-wrap:has(select):after {
    right: 24px;
  }
  select.form-control {
    padding-right: 48px;
  }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {


}


/* touchscreens */
@media (hover: none) {
}

/* mouse */
@media (hover: hover) {
  /* ... */
}