@media only screen and (max-width: 1199px) {
  .model-card-about {
    padding: 16px;
  }
  .model-card-controls {
    left: 16px;
    right: 16px;
    bottom: 16px;
  }
  .model-card:has(.model-card-controls):hover .model-card-about {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 991px) {
  .model-card-category {
    font-size: 12px;
    line-height: 18px;
    height: 26px;
    top: 8px;
    left: 8px;
  }
  .model-card-about {
    //overflow: hidden;
    padding: 16px;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: transparent !important;
    backdrop-filter: none !important;
    -webkit-backdrop-filter: none !important;
    border-radius: var(--model-border-radius);
    pointer-events: none;
    padding-bottom: 16px !important;

    &:before {
      right: 0px;
      bottom: 0px;
      left: 0px;
      top: auto;
      height: 56px;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
    }

    .model-card-about-title .name-age {
      font-size: 16px !important;
      line-height: 24px !important;
    }
  }

  .model-card-about-text, .model-card-about-arrow, .model-card-chat-text {
    display: none !important;
  }
  .model-card-controls {
    bottom: auto;
    left: auto;
    right: 8px;
    top: 8px;

    .model-card-chat {
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
      padding: 0;
      justify-content: center!important;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .model-card-generate {
    display: none;
  }
  .model-card-chat {
    background-color: rgba(7, 5, 19, 0.16) !important;
  }

}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 575px) {


}


/* touchscreens */
@media (hover: none) and (max-width: 991px) {
  .model-card-about {
    padding: 16px;
    pointer-events: none;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: transparent !important;
    backdrop-filter: none !important;
    -webkit-backdrop-filter: none !important;
    padding-bottom: 16px !important;

    .name-age {
      font-size: 24px !important;
      line-height: 32px !important;
    }
  }
  .model-card-controls {

  }
  .model-card-about-text, .model-card-about-arrow, .model-card-chat-text {
    display: none !important;
  }
  .model-card-controls {
    bottom: auto;
    left: auto;
    right: 8px;
    top: 8px;
  }
  .model-card-generate {
    display: none;
  }
  .model-card-chat {
    background-color: rgba(7, 5, 19, 0.16) !important;
  }

}

/* mouse */
@media (hover: hover) {
  /* ... */
}