:root {
  --bg-main: #070513;
  --bg-secondary: #201E2B;
  --bg-secondary-hover: #3E3A38;
  --text-color: #ffffff;
  --tint-color: #F50BEB;
  --secondary-color: #FFDE67;
  --muted-color: rgba(255,255,255,0.5);
  --dark-color: rgba(255,255,255,0.60);
  --divider: rgba(255,255,255,0.1);
  --border-color: rgba(255,255,255,0.1);

  --model-border-radius: 4px 4px 40px 4px;
  --page-padding: 24px;
  --footer-height: 80px;
  --header-height: 140px;


  --success: #5FCC43;
  --danger: #F03C3C;

  --font-regular: 'Poppins-Regular', sans-serif;
  --font-medium: 'Poppins-Medium', sans-serif;
  --font-semibold: 'Poppins-SemiBold', sans-serif;
  --font-bold: 'Poppins-Bold', sans-serif;
  --font-black: 'Poppins-Black', sans-serif;


  --transition: all .3s ease;
  --transition-1s: all 1s ease;


}

