label.req {
  font-weight: bolder;

  &:before {
    content: '*';
    color: var(--bs-danger);
    margin-right: 3px;
  }
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.form-group label {
  //font-family: var(--font-medium);
  color: var(--dark-color);
  margin-bottom: 8px;
  font-size: 14px;
}

.form-control-wrap {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 16px;
  //background-color: var(--bg-secondary);
  &:before {
    content: '';
    opacity: 0;
    transition: var(--transition);
    pointer-events: none;
    position: absolute;
    left: -3px;
    right: -3px;
    top: -3px;
    bottom: -3px;
    background-color: var(--tint-color);
    border-radius: 19px;
  }

  &:has(.form-control:focus ) {
    &:before {
      opacity: 0.2;
    }
  }
}

.input-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 12px;
  z-index: 1;
  pointer-events: none;
}

.form-group.clearable .form-control {
  padding-right: 56px;
}

.input-clear {
  z-index: 1;
  position: absolute;
  right: 16px;
}

.form-control {
  width: 100%;
  color: #fff;
  background: var(--bg-main);
  border-radius: 16px;
  min-height: 48px;
  font-size: 14px;
  z-index: 1;
  position: relative;
  padding-left: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);

}

.form-control::placeholder {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.64);
}

.form-control:focus {
  color: #fff;
  outline: 0;
  box-shadow: none;
  background: var(--bg-main);
  border: 1px solid var(--tint-color);
}

.form-control:active {
  color: #fff;
  outline: 0;
  box-shadow: none;
  background: var(--bg-main);
  border: 1px solid var(--tint-color);
}

.form-control-password {
  padding-right: 54px;
}

select.form-control {
  padding-right: 32px;
  //background: url("../../assets/icons/chevron.svg") no-repeat calc(100% - 8px) center, rgba(255, 255, 255, 0.1);
  color: #fff;

  &:has(option[value=""]:not(:checked)) {
    color: #fff !important;
  }
}

select.form-control[name= ''] {
  //color: var(--muted-color);
}

.form-control-wrap:has(select) {
  &:after {
    content: '';
    transition: var(--transition);
    z-index: 2;
    position: absolute;
    pointer-events: none;
    right: 16px;
    width: 16px;
    height: 16px;
    background: url("../../../../assets/icons/chevron_down.svg") no-repeat right center/ 100%;
  }

  &:before {
    border-radius: 22px;
  }
}

.form-control-wrap:has(select:active):after {
  transform: rotate(180deg);
}

.form-control-icon {
  padding-left: 44px;
}


.form-group-error {
  position: absolute;
  right: 0;
  color: var(--re);
  font-size: clamp(14px, 2vw, 16px);
}

.form-group-file {
  position: relative;
  font-size: clamp(14px, 2vw, 16px);
  display: flex;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  min-height: 56px;
  padding: 12px;
  padding-bottom: 4px;
}

.form-file-label {
  color: var(--muted-color);
  font-family: var(--font-bold);
  cursor: pointer;
  position: relative;
  height: 48px;
  border-radius: 32px;
  background: linear-gradient(#36353A, #36353A) padding-box, var(--gradient) border-box;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  padding: 0 20px 0 12px;
  transition: var(--transition);
}

.form-file-label-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-right: 4px;
  flex: 1;
}

.form-file-label:hover {
  color: var(--text-color)
}

.form-file-label path {
  transition: var(--transition);
}

.form-file-label:hover path {
  stroke-opacity: 1;
}

.form-group-file input {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.form-file-list-item {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: clamp(14px, 2vw, 16px);
  line-height: clamp(16px, 2vw, 20px);
  height: 32px;
  color: var(--muted-color);
  padding: 0 8px 0 12px;
  max-width: 202px;
  margin-bottom: 8px;
  margin-right: 8px;
  cursor: default;
}

.form-file-list-item:hover {
  color: var(--text-color)
}

.form-file-list-item:hover path {
  fill-opacity: 1;
}

.form-group-file-placeholder {
  font-family: var(--font-refular);
  color: rgba(255, 255, 255, 0.4);
}

.form-group-file .input-icon + .form-group-file-placeholder {
  padding-left: 46px;
}

.form-group-file .input-icon {
  left: 3px;
  top: 3px;
}

textarea.form-control {
  resize: none;
  padding-top: 12px;
  padding-bottom: 12px;
  min-height: 124px;

  ~ .input-icon {
    top: 12px;
  }
}

.input-pass-eye {
  cursor: pointer;
  position: absolute;
  right: 16px;
  width: 32px;
  height: 32px;
  z-index: 1;
}

.form-password-forgot {
  cursor: pointer;
  font-size: 14px;
  color: var(--tint-color);
  transition: var(--transition);
}


.input-file__progress {
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}

.form-file-icon {
  width: 104px;
  height: 104px;
  min-width: 104px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  margin-left: -8px;
  margin-top: -8px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.form-group.error .form-control {
  border-color: var(--danger);
}

.form-control option {
  background: var(--bg-main);
  font-family: var(--font-refular);
  margin-left: -32px;
  color: #fff;


  &.active {
    background-color: #202020;
  }

  &:hover {
    color: var(--bg-main);
  }

  &:checked {
    background-color: #202020;
  }

  &:disabled {
    opacity: 0;
    font-size: 0;
  }
}


.form-control[type= 'date'] {
  font-size: var(--font-refular);
  align-content: center;
  color: var(--muted-color);
}

input::-webkit-date-and-time-value {
  text-align: left;
}

.form-control[type= 'time'] {
  align-content: center;
}

.form-control[type= 'date']:valid {
  color: var(--text-color);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

input[type="date"]:before {
  content: '';
  width: 20px;
  top: 0;
  bottom: 0;
  pointer-events: none;
  //background: url("../../assets/images/icons/calendar.svg") no-repeat center;
  /* This is the calendar icon in FontAwesome */
  position: absolute;
  right: 12px;
  color: #999;
}

input[type="time"]:before {
  content: '';
  width: 20px;
  top: 0;
  bottom: 0;
  pointer-events: none;
  //background: url("../../assets/images/icons/time.svg") no-repeat center;
  /* This is the calendar icon in FontAwesome */
  position: absolute;
  right: 12px;
  color: #999;
}

.input-group {
  display: flex;
  align-items: flex-end;
}

.input-group .form-control {
  border-right-width: 0;
  border-radius: 0;
}


@media only screen and (max-width: 992px) {
  .form-group-file {
    /*padding: 8px;*/
    padding: 8px 8px 4px;
  }
  .form-file-label-wrap {
    padding-right: 0px;
  }
  .form-control {
    min-height: 48px;
  }

  .input-icon {
    width: 40px;
    height: 40px;
  }

  .input-icon svg {
    width: 24px;
  }

  .form-control-icon {
    padding-left: 44px;
  }

  textarea.form-control {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .form-control::placeholder, .form-group-file-placeholder, .form-group-file {
    font-size: 14px;
  }

  .form-file-label {
    font-size: 14px;
    height: 32px;
    border-radius: 32px;
  }

  .form-file-label svg {
    width: 18px;
  }

  .form-group-error {
    font-size: 14px;
  }
}

.form-check {
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;

}

.form-check-input {
  cursor: pointer;
  width: 24px;
  height: 24px;
  min-width: 24px;
  max-height: 24px;
  margin-top: 0;
  opacity: 0;

  &:focus {
    box-shadow: none;
  }
}

.form-check .form-check-input {

  &:hover + .form-check-custom {
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.15);
  }

  &:active + .form-check-custom {
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.15);
  }

  &:checked + .form-check-custom {
    background: var(--gradient);
    box-shadow: none;

    svg {
      display: block;
    }
  }
}

.form-check-label {
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--dark-color);
  padding-left: 12px;
}

.form-check-custom {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 6px;
  border: none;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  background-color: var(--bg-main);
  transition: var(--transition);
  color: var(--text-color);

  svg {
    display: none;
  }
}


.search-input {
  position: relative;
  display: flex;
  align-items: center;

  input.form-control {
    padding-left: 56px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.64);
      font-family: var(--font-regular);
    }
  }
}

.search-input.small {
  height: 40px;
  overflow: hidden;

  input.form-control {
    min-height: 40px;
    height: 40px;
    border-radius: 12px;
    padding-left: 40px;
    font-size: 14px;

    &::placeholder {
      font-size: 14px;
      text-align: left;
    }
  }

  .input-icon {
    z-index: 2;
    width: 20px;
    height: 20px;
    min-width: 20px;
    left: 12px;
  }
}

.form-group.sm .form-control {
  min-height: 40px;
  font-size: 14px;

  &::placeholder {
    font-size: 14px;
  }
}

.form-group.lg {
  .form-control-wrap {
    border-radius: 24px;
  }

  .form-control {
    min-height: 72px;
    padding-left: 32px;
    border-radius: 24px;

    &::placeholder {
      font-size: 16px;
    }
  }

}

.form-group.sm select.form-control {
  //background: url("../../assets/images/icons/caret.svg") no-repeat calc(100% - 14px) center;
}

.form-radio {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.form-radio-input:checked {
  & + .form-radio-custom + .form-radio-label {
    color: var(--tint-color);
  }

  & + .form-radio-custom {
    border: none;
    background: var(--gradient);

    &:after {
      opacity: 1;
    }
  }
}

.form-radio-input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  left: 0;
  width: 24px;
  min-width: 24px;
  height: 24px;

  &:hover {
    & + .form-radio-custom {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
}


.form-radio-label {
  font-family: var(--font-bold);
  transition: var(--transition);
  padding-left: 8px;
}

.form-select-placeholder {
  position: absolute;
  color: var(--text-muted);
}


.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback), {
  .form-control-wrap {
    border-radius: 0 24px 24px 0;
  }
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  .form-control-wrap {
    border-radius: 24px 0 0 24px;
  }
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

input[type='date']:in-range::-webkit-datetime-edit-year-field, input[type='date']:in-range::-webkit-datetime-edit-month-field, input[type='date']:in-range::-webkit-datetime-edit-day-field, input[type='date']:in-range::-webkit-datetime-edit-text, {
  color: transparent;
}

input[type='time']:in-range::-webkit-datetime-edit-year-field, input[type='time']:in-range::-webkit-datetime-edit-month-field, input[type='time']:in-range::-webkit-datetime-edit-day-field, input[type='time']:in-range::-webkit-datetime-edit-hour-field, input[type='time']:in-range::-webkit-datetime-edit-fields-wrapper, input[type='time']:in-range::-webkit-datetime-edit-text {
  color: transparent;
}

input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0px;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;

}

input[type="date"][value=""]:not(:focus), input[type="time"][value=""]:not(:focus), {
  color: transparent;
}

.form-control-placeholder {
  pointer-events: none;
  position: absolute;
  left: 24px;
  color: var(--muted-color);
  z-index: 1;
}

.input__file_wrap {
  position: relative;
  display: flex;
  align-items: center;

}

.input__file_container {
  position: relative;
  border-radius: 16px;
  display: inline-flex !important;
  max-width: 190px;
  width: 100%;

  &:before {
    transition: var(--transition);
    opacity: 0;
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    border: 1px inset var(--tint-color);
    pointer-events: none;
    filter: blur(5px);
  }

  &:hover:before {
    opacity: 1;
  }
}


.input__file {
  z-index: 1;
  //overflow: hidden;
  position: relative;
  cursor: pointer;
  display: inline-flex !important;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  padding: 2px;
  font-size: 14px;
  line-height: 20px;
  padding-right: 24px;
  width: 100%;
  //box-shadow: inset 0 0px 0px 1px var(--border-color);
  //border: 1px inset var(--border-color);
  &:before {
    transition: var(--transition);
    border-radius: inherit;
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    width: 50px;
    opacity: 0;
    pointer-events: none;
    background: linear-gradient(to left, var(--danger), transparent 100%);
  }

  &:after {
    transition: var(--transition);
    border-radius: inherit;
    content: '';
    position: absolute;
    inset: 0px;
    pointer-events: none;
  }

  &:hover {
    border-color: var(--tint-color);
  }

  &.uploading {
    pointer-events: none;
  }
}

.input__file_wrap:has(.input__file_trash:hover) .input__file::before {
  opacity: 1;
}

.input__file_left {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 14px;
  margin-right: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 14px;
  }

  .btn {
    min-height: auto;
    min-width: auto;
    aspect-ratio: 1;
    border-radius: 14px;
    width: 100%;
    height: 100%;
  }
}

.input__file_content {
  display: grid;
  overflow: hidden;
  width: 100%;
}

.input__file_left-asset {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  &:after {
    content: '';
    inset: 0;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: var(--transition);
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px;
    background: url("../../../../assets/icons/refresh.svg") no-repeat center center / 20px, linear-gradient(0deg, rgba(7, 5, 19, 0.32), rgba(7, 5, 19, 0.32));
  }
}

.input__file:hover .input__file_left-asset:after {
  opacity: 1;
}

.input__file_trash {
  cursor: pointer;
  margin-left: 8px;

  &:hover svg path {
    stroke: var(--danger);
  }

  svg path {
    transition: var(--transition);
    stroke: #fff;
  }
}

.input__file_progress {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transition: var(--transition);
  background: linear-gradient(to right, rgba(245, 11, 235, 0.4) 0%, rgba(245, 11, 235, 1) 100%);
}

.input__file_uploading {
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: url("../../../../assets/icons/close.svg") no-repeat center/ 24px;
  z-index: 2;
  position: relative;
  margin-right: 8px;
  cursor: pointer;
  display: none;
}
.input__file_uploading-border {
  position: absolute;
  display: block;
  inset: -1px;
  pointer-events: none;
  background: url("../../assets/icons/loader.png") no-repeat center / 100% 100%;
  animation: 2s linear rotate360 infinite;
}

.input__file_wrap.uploading {
  .input__file {
    padding: 0;
    border-color: transparent;
    overflow: visible;
    &:after, &:before {
      display: none;
    }
  }
  .input__file_container:before {
    display: none;
  }
  .input__file_left {
    display: none;
  }
  .input__file_uploading {
    display: flex;
  }
}