.react-responsive-modal-root {
  z-index: 1111;
}

.react-responsive-modal-overlay {
}

.react-responsive-modal-modal {
  //padding: 16px;
  padding: 0;
  background: none;
  box-shadow: none;
  margin: 0;
  max-width: 100%;
}


.react-responsive-modal-container {
  background: rgba(7, 5, 19, 0.2);
  backdrop-filter: blur(40px);
}


.modal__container {
  background-color: var(--bg-secondary);
  position: relative;
  padding: var(--page-padding);
  border-radius: 40px;
  align-self: center;
  width: 624px;
  max-width: calc(100% - 32px);
  margin: 0 auto;

  &.big {
    width: 924px;
  }

  &.medium {
    width: 700px;
  }
}

//@keyframes modal__container_animate {
//  0% {
//    transform: translateY(100%);
//  }
//  100% {
//    transform: translateY(0%);
//  }
//}
.modal__gallery {
  max-width: 100%;
}

.modal-close {
  display: inline-flex;
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 2;
  cursor: pointer;
}

.modal__title {
  margin-bottom: 24px;
  font-family: var(--font-medium);
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;

  h4 {
    font-family: var(--font-medium);
    font-size: 16px;
    line-height: 24px;
  }
}

.modal-footer {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 40px;

  &.sm {
    gap: 16px;
  }

  > * {
    flex: 1;
  }
}

.modal__gallery_item {
  display: flex !important;
  justify-content: center;
  max-height: 100%;
}

.gallery-modal-image {
  //width: auto!important;
  border-radius: 24px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  max-height: 90vh;

  //width: 300px;
  img {
    border-radius: 24px;
    object-fit: contain;
    width: auto;
    max-height: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
}

.modal__gallery {
  width: 100vw;
  height: 100dvh;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.modal__gallery_header {
  padding-bottom: 24px;
  display: none;
  align-items: flex-start;
  padding-top: 16px;
  width: 100%;
  //background-color: red;
  z-index: 1;
}

.modal__gallery_footer {
  display: none;
  width: 100%;
  padding: 24px var(--page-padding);
  justify-content: space-between;

  .btn-trash {
    opacity: 1;
    position: relative;
    top: auto;
    right: auto;
  }
}

.modal__gallery_content {
  position: relative;
  padding: 0;
  background-color: transparent;
  border: none;
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //align-items: center;
  //width: auto;
  .slick-prev {
    left: 16px;
  }

  .slick-next {
    right: 16px;
  }

  .btn-expand {
    left: 12px;
    top: 12px;
    transform: none;
  }
}

.modal__gallery .slick-slide {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modal__gallery .slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
}

.modal__gender_item {
  border-radius: 24px;
  border: 1px solid var(--border-color);
  transition: var(--transition);
  display: flex;
  width: 50%;
  cursor: pointer;
  height: 64px;
  position: relative;
  padding: 4px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 24px;
    border: 1px inset var(--tint-color);
    pointer-events: none;
    transition: var(--transition);
    filter: blur(5px);
    opacity: 0;
  }

  &:hover, &.active {
    border-color: var(--tint-color);

    &:before {
      opacity: 1;
    }

  }
}

.modal__gender_item-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  backdrop-filter: blur(16px);
}

.modal__container {
  .model-profile-slide img {
    width: auto;
  }

  .chats-side-list-container {
    display: block;
  }

  .chats-side-list {
    position: relative;
    overflow: visible;
  }

  .model-profile-info {
    padding: 0;
    padding-top: 16px;
  }
}

.modal__pushNotification_logo {
  width: 110px;
  margin: 16px auto 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  img {
    width: 100%;
  }
}

.generate-image-modal_models {
  max-height: 90dvh;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  min-height: 60dvh;
  padding-bottom: 8px;
  overflow: hidden;
  .model-card {
    aspect-ratio: 1/1.42;

    .name-age {
      font-size: 16px;
      line-height: 24px;
    }

    .text-truncate-2 {
    }
  }

}

.generate-image-modal_models-tabs {
  gap: 8px;
  margin-top: 16px;
  height: 48px;

  .tabs {
    width: calc(33% - 4px);
  }

  .horizontal-scroll {
    position: relative;
    padding: 0;
    width: 100%;
    justify-content: space-between;
  }
}


.generate__modal_character-list {
  margin-top: 12px;
  overflow: auto;
  height: max(200px, 60dvh);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal__chat_image_request-message {
  margin-top: 12px;
  background-color: var(--tint-color);
  border-radius: 0 16px 16px 16px;
  width: 100%;
  padding: 16px;
}

.modal__chat_image_request-option {
  margin-top: 8px;
}

.modal__chat_image_request-option:hover .modal__chat_image_request-check {
  display: block;
}

.modal__chat_image_request-check {
  display: none;
  margin-left: 8px;
}

.modal__chat_image_request-fire {
  margin-left: 8px;
  display: flex;
  justify-content: center;

  & > div {
    animation: fireScale step-start 0.6s alternate infinite;
  }

  &:before {
    content: '';
    position: absolute;
    width: 24px;
    height: 14px;
    background-color: var(--tint-color);
    filter: blur(10px);
  }

  svg {
    width: 28px;
    height: 28px;
  }
}

@keyframes fireScale {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(-1);
  }
  100% {
    transform: scaleX(1);
  }
}

.modal__lvl {
  padding: 24px 0 52px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 95dvh!important;
  .slick-dots {
    position: absolute;
    bottom: -20px;
  }

  .slick-slider {
    flex: 1;
    z-index: 1;
  }
}
.modal__lvl_content {
  //padding-bottom: 100px;
}

.modal__lvl_item-container {
  justify-content: center;
}

.modal__lvl_item {
  margin: 0 auto;
  background: rgba(7, 5, 19, 0.4);
  padding: 32px 24px 24px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
  max-width: 382px;
  width: 100%;
  overflow: hidden;
}

.modal__lvl_item-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  z-index: 1;
}

.modal__lvl_item-avatarBg {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: blur(60px);

  .avatar {
    width: 180px;
    height: 180px;
    position: absolute;

    &:first-child {
      margin-right: 126px;
    }

    &:last-child {
      margin-left: 126px;
    }
  }
}

.modal__lvl_item-header .avatar {
  &:first-child {
    margin-right: -14px;
  }

  &:last-child {
    margin-left: -14pxpx;
  }
}

.modal__lvl_item-separator {
  width: 100%;
  height: 1px;
  margin-bottom: 3px;
  margin-top: 12px;
  background-color: var(--border-color);
}

.btn.modal__lvl_btn {
  width: 100%;
  margin-top: 24px;
  opacity: 0;
  //min-height: 0;
  //height: 0;

}

.modal__lvl_item-container.active .btn.modal__lvl_btn {
  opacity: 1;
}

//.modal__lvl_item-container.active {
//  .btn.modal__lvl_btn {
//    animation: btnScale 0.1s forwards linear;
//    .btn-content {
//      animation: 0.1s  show 0.1s forwards linear;
//    }
//  }
//}

@keyframes btnScale {
  0% {
    padding-bottom: 0;
  }

  100% {
    padding-bottom: 48px;
  }
}

.modal__legacy {
  main {
    padding-top: 0;
  }
}

.modal__legacy_head {
  background: rgba(245, 11, 235, 0.2);
  padding: 8px;
  border-radius: 32px;
  display: flex;
}

.modal__legacy_message {
  background-color: var(--tint-color);
  font-family: var(--font-medium);
  padding: 16px;
  margin-top: 12px;
  border-radius: 0 24px 24px 24px;
}

.modal__payment {
  padding: 0;
  padding-top: 40px;
  overflow: hidden;
}

.modal__payment_slider {
  position: relative;
  min-height: 240px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 48px;
    background: linear-gradient(to right, var(--bg-secondary) 16px, transparent 100%);
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 48px;
    background: linear-gradient(to left, var(--bg-secondary) 16px, transparent 100%);
    z-index: 1;
  }
}

.modal__payment_slider-item {
  position: relative;
  padding: 0 8px;
  border-radius: 16px;
  aspect-ratio: 1/1.2;

  &.item__1 {
    margin-top: 20px;
  }

  img {
    border-radius: 16px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.modal__payment_content {
  padding: 24px;
  position: relative;
  padding-top: 120px;
  margin-top: -200px;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 0, var(--bg-secondary) 120px);
  }
}

.modal__adult_18 {
  padding-top: 32px;
}

.modal-gender-settings {
  padding-top: 40px;
}

.modal__chat__image_confirm-head {
  display: flex;
  margin-bottom: 24px;
}

.modal__chat__image_confirm {
  overflow-y: auto;

  .card__active_16:first-child {
    margin-top: 0;
  }
}

.payment__iframe_modal-container {
  padding-top: 54px;
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;

  .modal-close svg path {
    stroke: var(--bg-main);
  }
}

.payment__iframe_modal {
  width: 100%;
  border: none;
  height: 600px;
  max-height: 70vh;
}

.payment__methods_modal-process {
  display: flex;
  align-items: center;
  margin-top: 32px;
  padding: 32px 24px;
  background-color: var(--bg-main);
}

.payment__methods_modal-card {
  width: 298px;
  max-width: 100%;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  aspect-ratio: 1/0.6;
  background-color: var(--tint-color);
  padding-bottom: 24px;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.payment__methods_modal-cardNumber {
  font-size: 20px;
  line-height: 30px;
  margin-top: 12px;
}

.payment__methods_modal-cardholder {
  color: rgba(7, 5, 19, 0.2);
  margin-top: 16px;
  text-transform: uppercase;
}

.payment__methods_modal-loading {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.payment__methods_modal-loading {
  &.pending, &.finished {
    .payment__methods_modal-loading__process_item.pending {
      opacity: 1;
    }
  }
}
.payment__methods_modal-process:not(.loading) .payment__methods_modal-loading:not(.pending.finished) .payment__methods_modal-loading__process_card {
  opacity: 0;
}

.payment__methods_modal-loading:not(.finished).success .payment__methods_modal-loading__process_item.success {
  opacity: 1;
}

.payment__methods_modal-loading:not(.finished).failed .payment__methods_modal-loading__process_item.failed {
  opacity: 1;
}

.payment__methods_modal-loading__process_item {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: var(--transition);
  pointer-events: none;
  opacity: 0;
}

.payment__methods_modal-status__icon {
  height: 68px;
  width: 68px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #fff;

  &.failed {
    border-color: var(--danger);

    svg path {
      stroke: var(--danger);
    }
  }

  &.success {
    border-color: var(--success);

    svg path {
      stroke: var(--success);
    }
  }
}

.payment__methods_modal-loading__process {
  height: 80px;
  width: 80px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: conic-gradient(var(--bg-secondary) 14%, 0, var(--tint-color) 66%);
    animation: 3s linear infinite both running rotate360;
  }

  &:after {
    content: '';
    position: absolute;
    inset: 1px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: var(--bg-secondary);
  }
}


.payment__methods_modal-loading__process_card {
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 14px;
  border: 1px solid var(--tint-color);
  border-radius: 3px;
  animation: 1s ease-in both running;
  margin-bottom: 10px;

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    width: 12px;
    top: 2.5px;
    right: 0;
    background-color: var(--tint-color);
    border-radius: 4px;
  }
}

@keyframes payment__process_anim-card {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(40px);
  }
}

.payment__methods_modal-loading__process_card2 {
  margin-top: 12px;
  position: absolute;
  z-index: 1;
  width: 32px;
  height: 12px;
  border-radius: 4px 4px 0 0;
  border: 1px solid var(--tint-color);
  border-bottom: none;
  background: var(--bg-secondary);
}

.payment__methods_modal-loading.finished {
  .payment__methods_modal-loading__process_card {
    animation-name: payment__process_anim-card;
  }
  .payment__methods_modal-loading__process:before {
  }
}


.subscription__manage_modal {
  .settings__item {
    margin-top: 24px;
    border-color: var(--border-color) !important;
  }
}
.subscription__manage_about {
  background: rgba(7, 5, 19, 0.24);
  border-radius: 24px;
  padding: 16px;
  margin: 16px -8px -8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:nth-child(2) {
      border-left: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
    }
  }
}