@media only screen and (max-width: 1199px) {

}

@media only screen and (max-width: 991px) {
  .btn {
    //border-radius: 24px;
  }
  .btn.btn-lg {
    min-height: 64px;
    min-width: 64px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 20px;
    .btn-content {
      padding: 0 24px;
      min-height: 64px;
    }
    .react-icon svg {
      width: 24px;
      height: 24px;
    }
  }
  .btn-icon-left.btn-icon-absolute {
    left: 16px;
  }
  .btn.btn-lg.btn-outline-secondary, .btn.btn-lg.btn-outline-third {
    border-radius: 20px;
  }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {


}


/* touchscreens */
@media (hover: none) {
  .btn-animate:before {
    display: none;
  }
}

/* mouse */
@media (hover: hover) {
  /* ... */
}