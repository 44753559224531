.btn {
  user-select: none;
  color: var(--text-color);
  transition: var(--transition);
  font-size: 16px;
  line-height: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  min-width: 64px;
  //overflow: hidden;
  outline: none!important;
  border-radius: 24px;
  white-space: nowrap;
  letter-spacing: 0.01em;
  flex-wrap: nowrap;
  border: none;
  box-shadow: none!important;
  padding: 0;
  .btn-content {
    padding: 0 32px;
    min-height: 64px;
  }

  &:focus-visible {
    box-shadow: none;
  }

  .spinner {
    bottom: 6px;
  }
}

.btn-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  -webkit-text-fill-color: initial;
}

.btn.btn-primary {
  background: var(--tint-color);

  &:hover {
    background-color: #F630EE;
  }

  &:active {
    background: var(--tint-color);
  }
}

.btn.btn-secondary {
  background: rgba(255, 255, 255, 0.1);

  &:hover {
    background: rgba(255, 255, 255, 0.2) !important;
  }

  &:active {
    background: rgba(255, 255, 255, 0.05) !important;
  }
}

.btn.btn-outline {
  border: 1px solid var(--tint-color);

  &:hover {
    border: 1px solid #F630EE !important;
  }

  &:active {
    border: 1px solid var(--tint-color) !important;
  }
}
.btn.btn-outline-secondary {
  border: 1px solid var(--secondary-color);

  &:hover {
    background-color: transparent!important;
    border: 1px solid #fff !important;
  }

  &:active {
    background-color: transparent!important;
    border: 1px solid #fff !important;
  }
}
.btn.btn-outline-third {
  border: 1px solid var(--border-color);

  &:hover {
    background-color: transparent!important;
    border: 1px solid #fff !important;
  }

  &:active {
    background-color: transparent!important;
    border: 1px solid #fff !important;
  }
}

.btn.btn-white {
  background: #fff;
  color: var(--bg-main);

  &:hover {
    background: #fff !important;
    color: var(--bg-main);
  }

  &:active {
    background: #fff !important;
    color: var(--bg-main);
  }
}

.btn.btn-danger {
  background: rgba(227, 63, 113, 0.16);
  color: var(--tint-color);

  &:hover {
    background: rgba(227, 63, 113, 0.24) !important;
    color: var(--tint-color);
  }

  &:active {
    background: rgba(227, 63, 113, 0.08) !important;
    color: var(--tint-color);
  }
}
.btn.btn-yellow {
  background: var(--secondary-color);
  color: var(--bg-main);

  &:hover {
    background: var(--secondary-color) !important;
    color: var(--bg-main);
  }

  &:active {
    background: var(--secondary-color) !important;
    color: var(--bg-main);
  }
}

.btn.btn-third {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 60px;
  color: var(--muted-color);
  border: none;

  &:hover {
    background: rgba(255, 255, 255, 0.24) !important;
  }

  &:active {
    border: none;
    background: rgba(255, 255, 255, 0.08) !important;

  }

  &.active {
    background: #fff !important;

    .btn-content {
      background: -webkit-linear-gradient(left, #E33F71, #FD7153);
      background: -o-linear-gradient(right, #E33F71, #FD7153);
      background: -moz-linear-gradient(right, #E33F71, #FD7153);
      background: linear-gradient(to right, #E33F71, #FD7153);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.btn.disabled, .btn:disabled {
  pointer-events: none;
  background: rgba(255, 255, 255, 0.04) !important;

  .btn-content {
    opacity: 0.2 !important;
  }
}

.btn.loading {
  pointer-events: none;
}

.btn.btn-lg {
  min-height: 88px;
  min-width: 88px;
  font-size: 24px;
  line-height: 36px;
  border-radius: 32px;
  &.btn-outline-secondary, &.btn-outline-third {
    border-radius: 16px;
  }

  .btn-content {
    padding: 0 40px;
    min-height: 88px;
  }

  .react-icon svg {
    width: 40px;
    height: 40px;
  }

  .btn-icon-left {
    margin-left: -20px;

    &.btn-icon-absolute {
      left: 20px;
    }
  }

  .btn-icon-right {
    margin-right: -20px;

    &.btn-icon-absolute {
      right: 20px;
      margin-right: 0;
    }
  }

  .spinner {
    bottom: 12px;
  }
}

.btn.btn-md {
  min-height: 48px;
  min-width: 48px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 16px;

  .btn-content {
    padding: 0 24px;
    min-height: 48px;
  }

  .spinner {
    bottom: 3px;
  }
  .btn-icon-left {
    margin-right: 12px;
    min-width: 24px;
    &.btn-icon-absolute {
      left: 16px;
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .btn-icon-right {
    margin-left: 12px;

    &.btn-icon-absolute {
      right: 16px;
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.btn.btn-sm {
  min-height: 40px;
  min-width: 40px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 16px;

  .btn-content {
    padding: 0 24px;
    min-height: 40px;
  }


  .btn-icon-left {
    margin-right: 8px;

    &.btn-icon-absolute {
      left: 8px;
    }
  }

  .btn-icon-right {
    margin-left: 8px;

    &.btn-icon-absolute {
      right: 8px;
    }
  }

  .spinner {
    bottom: 1px;
  }
}

.btn.btn-xs {
  min-height: 24px;
  min-width: 24px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 8px;
  color: #fff;
  font-family: var(--font-regular);
  .btn-content {
    padding: 0 8px;
    min-height: 24px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &:after {
    border-radius: 12px;
  }

  .btn-icon-left {
    margin-left: 12px;

    &.btn-icon-absolute {
      left: 8px;
    }
  }

  .btn-icon-right {
    margin-left: 12px;

    &.btn-icon-absolute {
      right: 8px;
    }
  }

  .spinner {
    bottom: 1px;
  }
}

.btn-icon-left {
  font-size: 20px;
  margin-right: 16px;
  margin-left: -12px;

  &.btn-icon-absolute {
    margin-left: 0 !important;
    margin-right: 0 !important;
    position: absolute;
    left: 20px;

  }
}

.btn-icon-right {
  font-size: 20px;
  margin-left: 16px;
  margin-right: -12px;

  &.btn-icon-absolute {
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    right: 20px;
  }
}

.btn.btn-circle {
  border-radius: 50%;

  .btn-content {
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .btn-icon-left, .btn-icon-right {
    margin: 0;
  }
}

.btn.btn-icon {
  width: auto;
  align-self: center;
  .btn-content {
    width: auto;
    padding: 0!important;
  }
  .btn-icon-left {
    margin: 0;

  }
}


.btn.btn-animate {
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: var(--tint-color);
    filter: blur(20px);
    pointer-events: none;
    animation: infinityAnim 24s infinite linear;
    will-change: transform; /* Оптимизация для анимации */
    //bottom: -15px;
  }

  &.btn-animate_secondary:before {
    background-color: var(--secondary-color);
  }
}

@keyframes infinityAnim {
  0% {
    left: 50px;
    bottom: -1px;
  }
  20% {
    bottom: calc(100% + 1px);
    left: calc(100% - 50px);
  }
  35% {
    bottom: 50%;
    left: calc(100% - 15px);
  }
  50% {
    bottom: -40px;
    left: 100%;
  }
  70% {
    bottom: calc(100% + 1px);
    left: 24px;
  }
  85% {
    bottom: 50%;
    left: 0;
  }
  100% {
    left: 50px;
    bottom: -1px;
  }
}



//.modal__lvl_btn-price {
.btn__price {
  position: absolute;
  right: 8px;
  height: 32px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.2);
  padding: 0 8px;
  display: flex;
  align-items: center;
}
.alternative__title {
  display: none;
}
