header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 0 var(--page-padding);
  user-select: none;
  height: 136px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 100;


  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0, var(--bg-main) 48px, var(--bg-main) 100%);
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0, var(--bg-main) 48px, var(--bg-main) 100%);
    background: rgba(18, 18, 18, 0.08);
    //background: rgba(7, 5, 19, 0.6);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
  }
}

.header {
  .gender-select {
    z-index: 1;
    width: auto;
    opacity: 0;
    transition: var(--transition);
    pointer-events: none;
    position: absolute;
    left: 16px;
    align-self: center;
  }
}

header nav {
  display: flex;
  align-items: center;
  ul {
    margin-bottom: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .menu-item {
    padding: 12px 20px 12px 12px;
    opacity: 0.64;
    transition: var(--transition);
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    &:hover {
      opacity: 1;
      color: #fff;
    }

    &.active {
      background: rgba(255, 255, 255, 0.2);
      opacity: 1;
    }

    .react-icon {
      min-width: 24px;
    }
  }
}

.header {
  margin-top: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 48px;

  &.scrolled {
    .gender-select {
      pointer-events: auto;
      opacity: 1;
    }
  }
}

.header-right {
  display: flex;
  align-items: center;
  right: 0;
  position: absolute;
  gap: 8px;
  .btn-content {
    border-radius: 16px;
  }
}


.header-profile {
  position: absolute;
  z-index: 100;
  right: 0px;
  padding: 4px;
  backdrop-filter: blur(16px);
  background: rgba(7, 5, 19, 0.6);
  border-radius: 20px;
  top: calc(100% + 8px);
  display: none;
  border: 1px solid var(--border-color);
  min-width: 174px;

  &.visible {
    display: block;
  }
}

.header-avatar {
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover .header-avatar-arrow {
    opacity: 1;
  }

  &.visible .header-avatar-arrow {
    opacity: 1;
    transform: rotate(180deg);
  }
}

.header-avatar-arrow {
  opacity: 0.8;
  transition: var(--transition);
  margin-left: 2px;
  min-width: 24px;
}

.header-credits {
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding-right: 1px;
  padding-left: 8px;
  font-family: var(--font-bold);
  cursor: pointer;

  .react-icon {
    height: 36px;
    width: 36px;
    min-width: 36px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    margin-left: 16px;

  }
}

.header-logo {
  z-index: 1;
  user-select: none;
  height: 32px;
  display: flex;
  align-items: center;

  img {
    height: 100%;
    width: 103px;
  }
}

.header-center {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

.header-left {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
}

.header__secondary {
  display: none;
  height: var(--header-height);
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 0 0 32px 32px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(16px);

  &:before {
    display: none;
  }

  .gender-select {
    position: relative;
    left: auto;
    top: auto;
    margin: 0;
    height: auto;
  }

  .btn, .btn-content {
    min-height: 40px!important;
    height: 40px;
    border-radius: 16px;
  }
}

.menu-item-text {
  white-space: nowrap;
}

.btn.btn__header_coins {
  &:before {
    animation-duration: 8s!important;
  }
  .react-icon {
    min-width: 24px;
  }
  .btn-content {
    padding: 0 12px 0 14px;
  }
}
.btn__header_coins.anonymous {
  overflow: visible;
  &:before {
    display: none;
  }
  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 20px;
    border: 2px solid var(--bg-main);
    background: var(--tint-color);
    top: 0;
    right: 0;
  }
  .react-icon {
    display: none;
  }
  .btn-content {
    padding: 0 14px;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: var(--tint-color);
      border-radius: 40px;
      top: 2px;
      right: 2px;
      filter: blur(14px);
    }
  }
}

.header__secondary_left {
  position: absolute;
  padding: 16px;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  gap: 12px;
  min-height: 40px;
}

.header__secondary_right {
  position: absolute;
  padding: 16px;
  display: flex;
  align-items: center;
  right: 0;
  top: 0;
  bottom: 0;
  gap: 8px;
}

.header__secondary_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  h2 {
    font-size: 20px;
  }
}

.header__secondary_logo {
  height: 28px;
}

.header__secondary_logo-small {
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: none;
}

.header__secondary_stars {
  font-size: 14px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding-left: 10px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  height: 40px;

  &:hover {
    color: #fff;
  }
}

.nav__invisible {
  position: absolute;
  visibility: hidden;
}
.nav__hidden_container {
  position: relative;
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  &:has(.menu-item.active) {
    background: rgba(255, 255, 255, 0.2);
  }
}
.nav__hidden_container.visible {
  .nav__hidden_icon {
    opacity: 1;
    transform: rotate(180deg);
  }
  .nav__hidden_list {
    display: block;
  }
}
.nav__hidden_icon {
  height: 100%;
  flex: 1;
  border-radius: 16px;
  opacity: 0.64;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
.nav__hidden_list {
  //min-width: 174px;
  position: absolute;
  display: none;
  z-index: 20;
  left: 0;
  right: 0;
  top: calc(100% + 8px);
  ul {
    background-color: rgba(7, 5, 19, 0.6);
    border: 1px solid var(--border-color);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    backdrop-filter: blur(16px);
    padding: 4px;
    position: absolute;
    flex-direction: column-reverse;
    align-items: flex-start;
    li {
      width: 100%;
    }
  }
}
.menu__item_wrapper {
  display: flex;
  position: relative;
  align-items: center;
}
.menu__item_wrapper:has(.header__menu_plus) .menu-item {
  padding-right: 40px;

}
.header__menu_plus {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 12px;
  background: url("../../../../assets/icons/plus_active.svg") no-repeat center center / 14px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  margin-left: -6px;

  &:after {
    content: '';
    position: absolute;
    inset: -3px;
    border-radius: 8px;
    border: 1px solid var(--tint-color)
  }
}
