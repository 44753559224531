.spinner {
  width: 12px;
  height: 4px;
  display: flex;
  margin: auto;
  position: relative;
  border-radius: 4px;
  color: #FFF;
  background: currentColor;
  box-sizing: border-box;
  animation: animloader 0.6s 0.3s ease infinite alternate;
}
.spinner::after,
.spinner::before {
  content: '';
  box-sizing: border-box;
  width: 12px;
  height: 4px;
  background: currentColor;
  position: absolute;
  border-radius: 4px;
  top: 0;
  right: 110%;
  animation: animloader  0.6s ease infinite alternate;
}
.spinner::after {
  left: 110%;
  right: auto;
  animation-delay: 0.6s;
}

@keyframes animloader {
  0% {
    width: 12px;
  }
  100% {
    width: 24px;
  }
}